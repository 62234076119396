<template>
  <div>
    <section class="hero is-small">
      <div class="hero-body">
        <p class="title">Cadangan</p>

        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <div class="container">
              <ValidationObserver tag="form" ref="observer">
                <form @submit.prevent="submitCadangan()">
                  <validation-provider rules="required" v-slot="{ errors, valid }">
                    <b-field label="Jenis Cadangan" label-position="on-border" :type="{
                      'is-danger': errors[0],
                      'is-success': valid,
                    }" :message="errors">
                      <b-select v-model="cadangan.jenisCadangan" expanded>
                        <option value="tambah tukang">Tambah Kategori Tukang</option>
                        <option value="lain-lain">Lain-lain Cadangan</option>
                      </b-select>
                    </b-field>
                  </validation-provider>
                  <template v-if="cadangan.jenisCadangan == 'tambah tukang'">
                    <validation-provider rules="required" v-slot="{ errors, valid }">
                      <b-field :type="{
                        'is-danger': errors[0],
                        'is-success': valid,
                      }" :message="errors">
                        <b-input v-model="cadangan.tukangBaru" placeholder="Kategori tukang baru" type="text"></b-input>
                      </b-field>
                    </validation-provider>
                  </template>
                  <template v-if="cadangan.jenisCadangan == 'lain-lain'">
                    <validation-provider rules="required" v-slot="{ errors, valid }">
                      <b-field :type="{
                        'is-danger': errors[0],
                        'is-success': valid,
                      }" :message="errors">
                        <b-input v-model="cadangan.lainlain" type="textarea" maxlength="1000"
                          placeholder="Nyatakan cadangan anda">
                        </b-input>
                      </b-field>
                    </validation-provider>
                  </template>
                  <br />
                  <validation-provider rules="required|email" v-slot="{ errors, valid }">
                    <b-field label="Emel" label-position="on-border" :type="{
                      'is-danger': errors[0],
                      'is-success': valid,
                    }" :message="errors">
                      <b-input v-model="cadangan.email" type="email" icon-pack="fas" icon="envelope">
                      </b-input>
                    </b-field>
                    <br />
                  </validation-provider>

                  <b-button icon-left="paper-plane" size="is-medium" icon-pack="fa" type="is-warning"
                    native-type="submit">
                    <strong>Hantar</strong>
                  </b-button>
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "firebase";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "Ruangan ini adalah wajib diisi",
});

extend("email", {
  ...email,
  message: "Tidak menepati kriteria email",
});

export default {
  data() {
    return {
      cadangan: {
        jenisCadangan: "",
        tukangBaru: "",
        lainlain: "",
        email: "",
      },
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    setImage1: function (file) {
      this.hasImage1 = true;
      this.newPost.fotoBase64[0] = file;
      if (document.getElementById("fotoiklanlama1")) {
        document.getElementById("fotoiklanlama1").remove();
      }
    },

    async submitCadangan() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        console.log("failed");
        return;
      }
      console.log("mula cadangan");

      firebase
        .firestore()
        .collection("cadangan")
        .doc()
        .set(this.cadangan)
        .then(() => {
          console.log("New post successfully created!");
          this.$buefy.dialog.alert({
            message:
              "Terima kasih! Cadangan anda sangat kami alukan. Kami akan berusaha lagi memperbaiki CariTukang dari semasa ke semasa. ",
            confirmText: "Kembali",
          });


          // send email to me
          firebase.firestore().collection('mail').add({
            to: 'zarulzakuan@gmail.com',
            message: {
              subject: 'New suggestion has been submitted',
              html: JSON.stringify(this.cadangan, undefined, 4),
            },
          });
          this.$router.push({
            name: "Home",
          });
        })
        .catch((error) => {
          console.log(error);
        });

      

    },
  },
  mounted: function () { },
};
</script>
