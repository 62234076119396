<template>
  <div>
    <header class="card-header" style="overflow-x:hidden;">
      <b-tag type="is-dark">
        {{ post.tukang }}
      </b-tag>
      <b-tag type="is-light"> {{ post.lokasi }} &gt; {{ post.bandar }} </b-tag>
    </header>
    <div class="card-content">
      <div class="card-image">
        <b-carousel
          v-if="post.fotoBase64.length > 0"
          :indicator="true"
          :autoplay="false"
          :indicator-inside="true"
        >
          <b-carousel-item v-for="img64 in post.fotoBase64" :key="img64">
            <b-image :src="img64"></b-image>
          </b-carousel-item>
        </b-carousel>

        <div class="content" style="overflow: auto">
          <br />
          <div v-if="post.desc !== ''" class="content is-small">
            <div style="white-space: pre-wrap;">
              <v-clamp :ref="'desc' + index" autoresize :max-lines="maxlines">{{
                post.desc
              }}</v-clamp>
            </div>
            <!-- <pre>{{ post.desc }}</pre> -->
            <a v-if="maxlines < 6" v-on:click="showAll()">Lagi</a>

            <h3 v-if="post.khidmat.length > 0">Khidmat</h3>

            <ul>
              <li v-for="khid in post.khidmat" :key="khid">
                {{ khid }}
              </li>
            </ul>
            <div v-if="post.namaPerniagaan !== ''">
              <b-icon icon="building" pack="fas"></b-icon>
              <strong>{{ post.namaPerniagaan }} <span v-if="post.SSM !== ''">({{ post.SSM }})</span> </strong>
            </div>
          </div>
          <p>
            <b-button
              v-if="loggedUserID == post.id"
              icon-left="edit"
              icon-pack="fa"
              type="is-warning"
              size="is-small"
              @click="$router.push('/update')"
            >
              <strong>Kemaskini</strong>
            </b-button>
            <a
              @click="addToLikedItems(post.id)"
            >
              <b-button
              v-if="shortlisted != true"
                size="is-medium"
                icon-left="heart"
                icon-pack="fa"
                style="float: right"
                v-bind:class="{'is-light is-danger': !liked, 'is-light': liked}"
              >
              </b-button>
            </a>
            <a
              @click="deleteFromLikedItems(post.id)"
            >
              <b-button
              v-if="shortlisted == true"
                size="is-medium"
                icon-left="trash"
                icon-pack="fa"
                style="float: right"
                v-bind:class="{'is-light is-danger': !liked, 'is-light': liked}"
              >
              </b-button>
            </a>
            <a
              v-if="post.comms.includes('whatsapp')"
              @click="comms('whatsapp', post.phone)"
            >
              <b-button
                size="is-medium"
                icon-left="whatsapp"
                icon-pack="fab"
                type="is-success"
                style="float: right"
              >
              </b-button>
            </a>
            <a
              v-if="post.comms.includes('call')"
              @click="comms('call', post.phone)"
            >
              <b-button
                size="is-medium"
                icon-left="phone"
                icon-pack="fa"
                type="is-danger"
                style="float: right"
              >
              </b-button>
            </a>
            <a
              v-if="post.comms.includes('sms')"
              @click="comms('sms', post.phone)"
            >
              <b-button
                size="is-medium"
                icon-left="sms"
                icon-pack="fa"
                type="is-info"
                style="float: right"
              >
              </b-button>
            </a>
            <a
              v-if="post.website != undefined"
              @click="comms('web', post.website)"
            >
              <b-button
                size="is-medium"
                icon-left="globe"
                icon-pack="fa"
                type="is-link"
                style="float: right"
              >
              </b-button>
            </a>
            
            <!-- <b-button
                      size="is-medium"
                      icon-left="heart"
                      icon-pack="fa"
                      type="is-danger"
                      outlined
                      style="float: left"
                    >
                    </b-button> -->
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VClamp from "vue-clamp";
import {eventBus} from "../main";
export default {
  data: function() {
    return {
      maxlines: 5,
      liked: false,
      rowLimit: 20,
    };
  },
  components: {
    VClamp,
  },
  props: ["post", "index", "shortlisted"],
  methods: {
    comms(option, num) {
      switch (option) {
        case "call":
          window.location.href = "tel:" + num;
          break;
        case "whatsapp":
          window.location.href = "https://api.whatsapp.com/send/?phone=6" + num;
          break;
        case "sms":
          window.location.href = "sms:" + num;
          break;
        case "web":
          // window.location.href = num;
          if (!num.match(/^[a-zA-Z]+:\/\//)) {
            num = "http://" + num;
          }
          window.open(
            num,
            "_blank" // <- This is what makes it open in a new window.
          );
          break;
      }
    },
    showAll: function() {
      if (this.maxlines == 5) {
        this.maxlines = 1000;
      } else {
        this.maxlines = 5;
      }
    },
    addToLikedItems: function(postid) {
      eventBus.$emit('likedItems', postid)
      this.liked = true
    },
    deleteFromLikedItems: function(postid) {
      eventBus.$emit('removeLikedItems', postid)
      // this.removed = true
    }
  },
};
</script>
