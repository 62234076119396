<template>
  <b-navbar :fixed-top="true" :centered="true">
    <template #brand>
      <b-navbar-item>
        <img src="../assets/logo_beta.png" alt="Lightweight UI components for Vue.js based on Bulma"
          @click="$router.push('/')" />

        <img src="../assets/bendera.gif" alt="Lightweight UI components for Vue.js based on Bulma"
          style="padding-left: 15px" />
      </b-navbar-item>
      <b-navbar-item :style="{'margin':'auto'}">
        <b-button v-if="hasLiked" icon-left="heart" icon-pack="fa" type="is-light is-danger"
          @click="$router.push('/shortlisted')">
          <strong>{{ likeItems.length }}</strong>
        </b-button>
      </b-navbar-item>
    </template>
    <template #start>
      
    </template>

    <template #end>
      <b-navbar-item tag="div">
        <div class="buttons">
          <b-button v-if="loggedin" icon-left="sign-out-alt" icon-pack="fa" type="is-dark" @click="logout">
            <strong>Log Keluar</strong>
          </b-button>
          <b-button v-else icon-left="sign-in-alt" icon-pack="fa" type="is-info" @click="$router.push('/login')">
            <strong>Log Masuk / Daftar</strong>
          </b-button>
          <b-button v-if="loggedin" icon-left="edit" icon-pack="fa" type="is-warning" @click="$router.push('/update')">
            <strong>Iklan</strong>
          </b-button>

          <b-button v-else icon-left="edit" icon-pack="fa" type="is-warning" @click="$router.push('/login')">
            <strong>Iklankan Servis Anda Percuma!</strong>
          </b-button>
          <!-- <b-button
            icon-left="dollar-sign"
            icon-pack="fa"
            type="is-success"
            @click="$router.push('/donate')"
          >
            <strong>Derma</strong>
          </b-button> -->
        </div>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import firebase from "firebase";
import { eventBus } from "../main";
export default {
  data() {
    return {
      loggedin: false,
      hasLiked: false,
      likeCount: 0,
      likeItems: [],
    };
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$buefy.toast.open({
            message: "Ada telah log keluar",
            type: "is-success",
          });
          this.$router.push("/");
        })
        .catch((error) => {
          //alert(error.message);
          console.log(error);
          this.$router.push("/");
        });
    }
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.loggedin = true;
        console.log("logged in");
      } else {
        console.log("logged out");
        this.loggedin = false;
      }
    });

    if (localStorage.shortlisted) {
      let items = JSON.parse(localStorage.shortlisted)
      if (items.length > 0) {
        this.hasLiked = true
        this.likeItems = items
      }

    }

    eventBus.$on('likedItems', (item) => {
      if (!this.likeItems.includes(item)) {
        this.hasLiked = true
        this.likeItems.push(item)
        localStorage.shortlisted = JSON.stringify(this.likeItems)
      }

    });
    eventBus.$on('removeLikedItems', (item) => {
      if (this.likeItems.includes(item)) {

        const index = this.likeItems.indexOf(item);
        if (index > -1) {
          this.likeItems.splice(index, 1);
        }
        localStorage.shortlisted = JSON.stringify(this.likeItems)
        if (this.likeItems.length < 1) {
          this.hasLiked = false
        }
      }

    });
  },
};
</script>
