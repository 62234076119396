import Vue from 'vue'
import VueRouter from 'vue-router'
import Create from '../components/Create.vue'
import Listing from '../components/Showcase.vue'
import Update from '../components/Update.vue'
import Home from '../components/Home.vue'
import Login from '../components/Login.vue'
import Admin from '../components/Admin.vue'
import Cadangan from '../components/Cadangan.vue'
import Shortlisted from '../components/Shortlisted.vue'
import firebase from "firebase";

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: true,
  },
  {
    path: '/create',
    name: 'Create',
    component: Create
  },
  {
    path: '/update',
    name: 'Update',
    component: Update,
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      isAdmin: true,
    },
  },
  {
    path: '/listing/:tukang/:kawasan/:bandar',
    name: 'Listing',
    component: Listing,
    props: true,
  },
  {
    path: '/shortlisted',
    name: 'Shortlisted',
    component: Shortlisted,
  },
  {
    path: '/cadangan',
    name: 'Cadangan',
    component: Cadangan,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.authRequired);
  const onlyAdmin = to.matched.some(record => record.meta.isAdmin);
  let user = await firebase.getCurrentUser();
  if (user != null) {

    let adminUserUID = user.uid;

    if (onlyAdmin){
     if (adminUserUID == "np3ljjMN6igal01e0hLjVRoBTcH2"){
       next();
     }else{
      next('/');
     }
    }

      next();
    
  }else{
    if (requiresAuth || onlyAdmin) {
      if (onlyAdmin){
        console.log("only admin")
        next('/');
      }else{
        next('/login');
      }
      
    }else{
      next();
    }
  }
});

export default router
