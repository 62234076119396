import Vue from 'vue'
import App from './App.vue'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import VueRouter from 'vue-router'
import router from './router'
import firebase from "firebase";
import ImageUploader from 'vue-image-upload-resize'
import VueTypedJs from 'vue-typed-js'
import VueMobileDetection from 'vue-mobile-detection'
import VueNumber from 'vue-number-animation'

export const eventBus = new Vue();

const firebaseConfig = {
  apiKey: "AIzaSyDH6IFlY1_UyufMVKlVvr9Uu0vEXxFMKbY",
  authDomain: "caritukang-90675.firebaseapp.com",
  databaseURL: "https://caritukang-90675.firebaseio.com",
  projectId: "caritukang-90675",
  storageBucket: "caritukang-90675.appspot.com",
  messagingSenderId: "853364326689",
  appId: "1:853364326689:web:d3236aed8a75cce4e0be60",
  measurementId: "G-L7M280V7Y8"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      unsubscribe();
      resolve(user);
    }, reject);
  }
  )
};

let tukangtemp = [];
let postCount = 9999;

function getCatagoryList() {
  var docRef = firebase.firestore().collection("categories").orderBy("name");
  docRef
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        tukangtemp.push(doc.data());

      });
      new Vue({
        router,
        render: h => h(App)
      }).$mount('#app')
    })
    .catch((error) => {
      alert("Error getting document:", error);
    });
}

getCatagoryList();

async function getAllPostCount() {
  // var docRef = firebase.firestore().collection("posts");
  // const query = docRef.where('active', '==', true);
  // const snapshot = await query.get();
  postCount = 982;
  // console.log("Post count: " + postCount)
}

getAllPostCount();

const sharedData = {
  states: [
    "Seluruh Malaysia",
    // "Semenanjung Malaysia",
    // "Sabah dan Sarawak",
    "Johor",
    "Kedah",
    "Kelantan",
    "Kuala Lumpur",
    "Labuan",
    "Melaka",
    "Negeri Sembilan",
    "Pahang",
    "Perak",
    "Perlis",
    "Pulau Pinang",
    "Putrajaya",
    "Sabah",
    "Sarawak",
    "Selangor",
    "Terengganu",
  ],
  "Seluruh Malaysia": [
    "Semua Negeri"
  ],
  Johor: [
    "Seluruh Johor",
    "Batu Pahat",
    "Bukit Bakri",
    "Bukit Indah",
    "Buloh Kasap",
    "Chaah",
    "Johor Bahru",
    "Kelapa Sawit",
    "Kluang",
    "Kota Tinggi",
    "Kulai",
    "Labis",
    "Mersing",
    "Muar",
    "Parit Raja",
    "Pasir Gudang",
    "Pekan Nenas",
    "Pontian Kechil",
    "Segamat",
    "Senai",
    "Simpang Renggam",
    "Skudai",
    "Tangkak",
    "Tebrau",
    "Ulu Tiram",
    "Yong Peng",
  ],
  Kedah: [
    "Seluruh Kedah",
    "Alor Setar",
    "Baling",
    "Bandar Baharu",
    "Jitra",
    "Kuala Nerang",
    "Kulim",
    "Langkawi",
    "Pendang",
    "Pokok Sena",
    "Sik",
    "Sungai Petani",
    "Yan",
  ],
  Kelantan: [
    "Seluruh Kelantan",
    "Gua Musang",
    "Kadok",
    "Kota Bharu",
    "Kuala Krai",
    "Pasir Mas",
    "Pengkalan Kubor",
    "Peringat",
    "Tanah Merah",
    "Tumpat",
    "Wakaf Bharu",
  ],
  "Kuala Lumpur": [
    "Seluruh Kuala Lumpur"
  ],
  Labuan: [
    "Seluruh Labuan"
  ],
  Melaka: [
    "Seluruh Melaka",
    "Alor Gajah",
    "Ayer Keroh",
    "Ayer Molek",
    "Bandaraya Melaka",
    "Batu Berendam",
    "Bemban",
    "Bukit Baru",
    "Bukit Rambai",
    "Klebang",
    "Kuala Sungai Baru",
    "Masjid Tanah",
    "Pulau Sebang",
    "Sungai Udang",
  ],
  "Negeri Sembilan": [
    "Seluruh Negeri Sembilan",
    "Bahau",
    "Kuala Pilah",
    "Nilai",
    "Port Dickson",
    "Seremban",
    "Tampin",
  ],
  Pahang: [
    "Seluruh Pahang",
    "Bandar Jengka",
    "Bentong",
    "Bukit Tinggi",
    "Jerantut",
    "Kuala Lipis",
    "Kuantan",
    "Mentakab",
    "Pekan",
    "Raub",
    "Temerloh",
  ],
  Perak: [
    "Seluruh Perak",
    "Ayer Tawar",
    "Bagan Serai",
    "Batu Gajah",
    "Bidor",
    "Bota",
    "Ipoh",
    "Kampar",
    "Kampung Gajah",
    "Kampung Koh",
    "Kuala Kangsar",
    "Lawan Kuda Baharu",
    "Lumut",
    "Pantai Remis",
    "Parit",
    "Parit Buntar",
    "Simpang Empat",
    "Sitiawan",
    "Sungai Siput",
    "Taiping",
    "Tanjung Malim",
    "Tapah",
    "Teluk Intan",
  ],
  Perlis: [
    "Seluruh Perlis",
    "Arau",
    "Kangar",
    "Padang Besar",
  ],
  "Pulau Pinang": [
    "Seluruh Pulau Pinang",
    "Air Itam",
    "Bukit Mertajam",
    "Butterworth",
    "Gelugor",
    "Georgetown",
    "Juru",
    "Kepala Batas",
    "Nibong Tebal",
    "Perai",
    "Permatang Pauh",
    "Sungai Ara",
    "Tanjung Bungah",
    "Tanjung Tokong",
  ],
  Putrajaya: [
    "Seluruh Putrajaya",
  ],
  Sabah: [
    "Seluruh Sabah",
    "Beaufort",
    "Donggongon",
    "Keningau",
    "Kinarut",
    "Kota Belud",
    "Kota Kinabalu",
    "Kudat",
    "Kunak",
    "Lahad Datu",
    "Papar",
    "Putatan",
    "Ranau",
    "Sandakan",
    "Semporna",
    "Tawau",
  ],
  Sarawak: [
    "Seluruh Sarawak",
    "Batu Delapan Bazaar",
    "Bintulu",
    "Kapit",
    "Kota Samarahan",
    "Kuching",
    "Limbang",
    "Miri",
    "Sarikei",
    "Sibu",
    "Sri Aman",
  ],
  Selangor: [
    "Seluruh Selangor",
    "Ampang Jaya",
    "Balakong",
    "Bandar Baru Salak Tinggi",
    "Banting",
    "Batang Berjuntai",
    "Batu Arang",
    "Beranang",
    "Bukit Beruntung",
    "Cheras",
    "Gombak Setia",
    "Jenjarom",
    "Kajang",
    "Klang",
    "Kuala Kubu Baharu",
    "Kuala Selangor",
    "Kuang",
    "Pengkalan Kundang",
    "Petaling Jaya",
    "Rawang",
    "Sabak",
    "Sekinchan",
    "Selayang",
    "Semenyih",
    "Serendah",
    "Shah Alam",
    "Subang Jaya",
    "Sungai Besar",
    "Sungai Pelek",
    "Taman Greenwood",
    "Tanjung Karang",
    "Tanjung Sepat",
  ],
  Terengganu: [
    "Seluruh Terengganu",
    "Chukai",
    "Dungun",
    "Jerteh",
    "Kerteh",
    "Kuala Berang",
    "Kuala Terengganu",
    "Marang",
    "Paka",
  ],
  tukangtemp,
  postCount
}

sharedData.install = function () {
  Object.defineProperty(Vue.prototype, '$sharedData', {
    get() { return sharedData }
  })
}

Vue.use(sharedData);

Vue.use(VueMobileDetection)
Vue.use(VueTypedJs)
Vue.use(ImageUploader);
Vue.use(VueRouter)
Vue.use(Buefy);
Vue.use(VueNumber);
Vue.config.productionTip = false






