<template>
  <div>
    <section class="hero is-small">
      <div class="hero-body">
        <p class="title">Daftar Iklan</p>
        <b-steps
          type="is-info"
          :destroy-on-hide="true"
          :has-navigation="false"
          prevIcon="chevron-left"
          nextIcon="chevron-right"
          icon-pack="fa"
        >
          <b-step-item :clickable="false" step="1" label="Daftar">
            <div class="columns is-centered">
              <div class="column is-three-quarters">
                <div class="container">
                  <br />
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(validateForm)">
                      <validation-provider
                        rules="required"
                        v-slot="{ errors, valid }"
                      >
                        <b-field
                          label="Kategori Tukang *"
                          label-position="on-border"
                          :type="{
                            'is-danger': errors[0],
                            'is-success': valid,
                          }"
                          :message="errors"
                        >
                          <b-select v-model="newPost.tukang" expanded>
                            <option disabled value="">Pilih satu ...</option>
                            <option value="1">Option 1</option>
                            <option value="2">Option 2</option>
                          </b-select>
                        </b-field>
                      </validation-provider>

                      <br />

                      <validation-provider
                        rules="required"
                        v-slot="{ errors, valid }"
                      >
                        <b-field
                          label="Lokasi *"
                          label-position="on-border"
                          :type="{
                            'is-danger': errors[0],
                            'is-success': valid,
                          }"
                          :message="errors"
                        >
                          <b-select v-model="newPost.lokasi" expanded>
                            <option disabled value="">Pilih satu ...</option>
                            <option value="1">Option 1</option>
                            <option value="2">Option 2</option>
                          </b-select>
                        </b-field>
                      </validation-provider>

                      <br />
                      <br />

                      <b-field label="Foto Iklan" label-position="on-border">
                        <div class="columns is-mobile">
                          <div class="column has-text-centered">
                            <image-uploader
                              id="fotoiklan1"
                              :preview="true"
                              :className="[
                                'fotoiklan1',
                                { 'fotoiklan1--loaded': hasImage1 },
                              ]"
                              :debug="0"
                              :autoRotate="true"
                              outputFormat="string"
                              :maxHeight="500"
                              :maxWidth="500"
                              @input="setImage1"
                            >
                              <label for="fotoiklan1" slot="upload-label">
                                <figure>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                  >
                                    <path
                                      class="path1"
                                      d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                                    ></path>
                                  </svg>
                                </figure>
                                <b-tag class="upload-caption">{{
                                  hasImage1 ? "Tukar" : "Foto 1"
                                }}</b-tag>
                              </label>
                            </image-uploader>
                          </div>
                          <div class="column has-text-centered">
                            <image-uploader
                              id="fotoiklan2"
                              :preview="true"
                              :className="[
                                'fotoiklan2',
                                { 'fotoiklan2--loaded': hasImage2 },
                              ]"
                              :debug="0"
                              :autoRotate="true"
                              outputFormat="string"
                              :maxHeight="500"
                              :maxWidth="500"
                              @input="setImage2"
                            >
                              <label for="fotoiklan2" slot="upload-label">
                                <figure>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                  >
                                    <path
                                      class="path1"
                                      d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                                    ></path>
                                  </svg>
                                </figure>
                                <b-tag class="upload-caption">{{
                                  hasImage2 ? "Tukar" : "Foto 2"
                                }}</b-tag>
                              </label>
                            </image-uploader>
                          </div>
                          <div class="column has-text-centered">
                            <image-uploader
                              id="fotoiklan3"
                              :preview="true"
                              :className="[
                                'fotoiklan3',
                                { 'fotoiklan3--loaded': hasImage3 },
                              ]"
                              :debug="0"
                              :autoRotate="true"
                              outputFormat="string"
                              :maxHeight="500"
                              :maxWidth="500"
                              @input="setImage3"
                            >
                              <label for="fotoiklan3" slot="upload-label">
                                <figure>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                  >
                                    <path
                                      class="path1"
                                      d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                                    ></path>
                                  </svg>
                                </figure>
                                <b-tag class="upload-caption">{{
                                  hasImage3 ? "Tukar" : "Foto 3"
                                }}</b-tag>
                              </label>
                            </image-uploader>
                          </div>
                        </div>
                      </b-field>

   
                      <br />

                      <validation-provider
                        rules="required|email"
                        v-slot="{ errors, valid }"
                      >
                        <b-field
                          label="Email *"
                          label-position="on-border"
                          :type="{
                            'is-danger': errors[0],
                            'is-success': valid,
                          }"
                          :message="errors"
                        >
                          <b-input v-model="newPost.email" type="email">
                          </b-input>
                        </b-field>
                      </validation-provider>
                      <br />
                      <b-field
                        label="Deskripsi Ringkas"
                        label-position="on-border"
                      >
                        <b-input
                          maxlength="200"
                          type="textarea"
                          v-model="newPost.desc"
                          placeholder="E.g., Saya menyediakan perkhidmatan tukang rumah dan kerja2 berkaitan dengannya"
                        ></b-input>
                      </b-field>

                      <b-field
                        label="Senarai Perkhidmatan"
                        label-position="on-border"
                      >
                        <b-taginput
                          maxtags="5"
                          v-model="newPost.khidmat"
                          type="is-info"
                          ellipsis
                          icon="wrench"
                          icon-pack="fa"
                          placeholder="E.g., Servis cucian aircond"
                        >
                        </b-taginput>
                      </b-field>
                      <br />
                      <b-field
                        label="Nama Syarikat/Perniagaan"
                        label-position="on-border"
                      >
                        <b-input maxlength="30"></b-input>
                      </b-field>

                      <b-field label="No. SSM" label-position="on-border">
                        <b-input maxlength="30"></b-input>
                      </b-field>

                      <validation-provider
                        rules="required|max:12|min:10|integer"
                        v-slot="{ errors, valid }"
                      >
                        <b-field
                          label="Nombor Telefon *"
                          label-position="on-border"
                          :type="{
                            'is-danger': errors[0],
                            'is-success': valid,
                          }"
                          :message="errors"
                        >
                          <b-input
                            v-model="newPost.phone"
                            maxlength="12"
                          ></b-input>
                        </b-field>
                      </validation-provider>

                      <br />
                      <validation-provider
                        rules="required|min:1"
                        v-slot="{ errors, valid }"
                      >
                        <b-field
                          label="Mod Perhubungan *"
                          label-position="on-border"
                        >
                        </b-field>
                        <br />
                        <b-field
                          :type="{
                            'is-danger': errors[0],
                            'is-success': valid,
                          }"
                          :message="errors"
                        >
                          <b-checkbox
                            v-model="newPost.comms"
                            native-value="sms"
                            type="is-info"
                          >
                            SMS
                          </b-checkbox>

                          <b-checkbox
                            v-model="newPost.comms"
                            native-value="whatsapp"
                            type="is-success"
                          >
                            Whatsapp
                          </b-checkbox>

                          <b-checkbox
                            v-model="newPost.comms"
                            native-value="call"
                            type="is-danger"
                          >
                            Call
                          </b-checkbox>
                        </b-field>
                      </validation-provider>
                      <hr />

                      <validation-provider
                        rules="required"
                        v-slot="{ errors, valid }"
                      >
                        <b-field
                          :type="{
                            'is-danger': errors[0],
                            'is-success': valid,
                          }"
                          :message="errors"
                        >
                          <b-checkbox v-model="newPost.agreed" type="is-info">
                            Saya setuju dengan <a href="#">Terma dan Syarat</a>
                          </b-checkbox>
                        </b-field>
                      </validation-provider>
                      <br />
                      <validation-provider
                        rules="required|min_value:1"
                        v-slot="{ errors, valid }"
                      >
                        <b-field
                          :type="{
                            'is-danger': errors[0],
                            'is-success': valid,
                          }"
                          :message="errors"
                        >
                          <div id="recaptcha-container"></div>
                          <b-input
                            style="display: none"
                            v-model="recaptchaSolved"
                          ></b-input>
                        </b-field>
                        <br />
                      </validation-provider>
                      <nav class="step-navigation">
                        <b-button
                          icon-left="paper-plane"
                          size="is-medium"
                          icon-pack="fa"
                          type="is-warning"
                          native-type="submit"
                        >
                          <strong>Daftar</strong>
                        </b-button>
                      </nav>
                    </form>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </b-step-item>

          <b-step-item :clickable="true" step="2" label="Semak">
            <div class="columns is-centered">
              <div class="column is-one-third">
                <div class="container has-text-centered">
                  <img
                    src="https://thumbs.gfycat.com/FantasticDownrightIberianchiffchaff-max-1mb.gif"
                    alt="Lightweight UI components for Vue.js based on Bulma"
                  />
                  <h1 class="title has-text-centered">
                    Isi Kod Pengesahan 6 digit dari SMS yang anda terima
                  </h1>
                  <section>
                    <ValidationObserver v-slot="{ invalid }">
                      <b-field custom-class="is-large">
                        <validation-provider
                          rules="required|length:6"
                          v-slot="{ errors }"
                        >
                          <b-input
                            v-model="otp"
                            placeholder="123456"
                            size="is-large"
                            name="otpField"
                          ></b-input>
                          <span>{{ errors[0] }}</span>
                        </validation-provider>
                        <p class="control">
                          <b-button
                            label=""
                            type="is-warning"
                            size="is-large"
                            icon-pack="fa"
                            icon-right="chevron-right"
                            @click="verifyCode"
                            :disabled="invalid"
                          />
                        </p>
                      </b-field>
                    </ValidationObserver>
                  </section>
                </div>
              </div>
            </div>
          </b-step-item>

          <b-step-item step="3" label="Selesai">
            <div class="columns is-centered">
              <div class="column is-one-third">
                <div class="container has-text-centered">
                  <img
                    src="https://trainingcoach.in/assets/img/success.gif"
                    alt="Lightweight UI components for Vue.js based on Bulma"
                  />
                  <h1 class="title has-text-centered">Terima Kasih</h1>
                  <h2 class="subtitle">
                    Iklan anda telah tersenarai. Tahniah!
                  </h2>
                  <b-button
                    label="Kembali"
                    type="is-warning"
                    size="is-large"
                    icon-pack="fa"
                    icon-right="home"
                  />
                </div>
              </div>
            </div>
          </b-step-item>
          <template
            v-if="customNavigation"
            slot="navigation"
            slot-scope="{ next }"
          >
            <b-button
              id="nextButton"
              style="display: none"
              type="is-warning"
              size="is-medium"
              icon-pack="fas"
              icon-right="chevron-right"
              :disabled="next.disabled"
              @click.prevent="next.action"
            >
              Next
            </b-button>
          </template>
        </b-steps>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "firebase";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  required,
  length,
  max,
  min,
  integer,
  ext,
  email,
  min_value,
} from "vee-validate/dist/rules";
import ImageUploader from "vue-image-upload-resize";

extend("required", {
  ...required,
  message: "Ruangan ini adalah wajib diisi",
});

extend("length", {
  ...length,
  message: "Tidak mencapai minimum pilihan",
});

extend("max", {
  ...max,
  message: "Melebihi maksimum aksara",
});

extend("min", {
  ...min,
  message: "Tidak mencapai minimum aksara",
});

extend("integer", {
  ...integer,
  message: "Bukan dijit",
});

extend("min_value", {
  ...min_value,
  message: "reCAPTCHA perlu diselesaikan",
});

extend("ext", {
  ...ext,
  message: "Format gambar hendaklah JPEG atau PNG ",
});

extend("email", {
  ...email,
  message: "Tidak menepati kriteria email",
});

export default {
  data() {
    return {
      newPost: {
        phone: "0194301275",
        comms: ["call"],
        agreed: "true",
        tukang: "kayu",
        lokasi: "perak",
        fotoBase64: [],
        email: "zarulzakuan@gmail.com",
        khidmat: ["hello", "test"],
        userID: "",
        desc: "Test description test test",
      },

      activeStep: 0,
      customNavigation: true,

      hasImage1: false,
      image1: null,
      hasImage2: false,
      image2: null,
      hasImage3: false,
      image3: null,
      appVerifier: "",
      otp: "",

      recaptchaSolved: 0,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
  },
  methods: {
    setImage1: function (file) {
      this.hasImage1 = true;
      this.newPost.fotoBase64[0] = file;
    },
    setImage2: function (file) {
      this.hasImage2 = true;
      this.newPost.fotoBase64[1] = file;
    },
    setImage3: function (file) {
      this.hasImage3 = true;
      this.newPost.fotoBase64[2] = file;
      console.log(this.newPost.fotoBase64)
    },
    validateForm() {
      this.sendOtp();
      // const elem = document.getElementById("nextButton");
      // elem.click();
    },
    verifyCode() {
      this.verifyOtp();
      //const elem = document.getElementById("nextButton");
      //elem.click();
    },
    sendOtp() {
      var self = this;
      if (this.newPost.phone.length < 0) {
        alert("Invalid Phone Number Format !");
      } else {
        //
        let phoneNumber = this.newPost.phone;
        //
        let appVerifier = this.appVerifier;
        //

        firebase.auth().settings.appVerificationDisabledForTesting = true;
        firebase
          .auth()
          .signInWithPhoneNumber("+6" + phoneNumber, appVerifier)
          .then(function (confirmationResult) {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            window.confirmationResult = confirmationResult;
            console.log(confirmationResult);
            //
            console.log("SMS sent");
            self.createUser();
          })
          .catch(function (error) {
            // Error; SMS not sent
            // ...
            console.log(error);
            alert("Error ! SMS not sent");
          });
      }
    },
    createUser() {
      firebase
        .firestore()
        .collection("posts").doc(this.newPost.phone+this.newPost.email)
        .set(this.newPost)
        .then(() => {
          console.log("New post successfully created!");
          // send email to me
          firebase.firestore().collection('mail').add({
            to: 'zarulzakuan@gmail.com',
            message: {
              subject: 'New post has been submitted',
              html: JSON.stringify(this.newPost, undefined, 4),
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    verifyOtp() {
      //

      let code = this.otp;
      //
      window.confirmationResult
        .confirm(code)
        .then(function (result) {
          // User signed in successfully.
          var user = result.user;
          console.log("Logged in");
          console.log(user);
          // ...
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    initReCaptcha() {
      // let vm = this;
      console.log("Init reCaptcha");
      // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
      //
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          theme: "light",
          size: "compact",
          callback: (response) => {
            console.log(response);
            this.recaptchaSolved = 1;
          },
        }
      );
      this.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });
      this.appVerifier = this.recaptchaVerifier;
    },

    hello() {
      console.log("OK");
    },
  },
  mounted: function () {
    this.initReCaptcha();
  },
};
</script>

<style>
#fotoiklan1,
#fotoiklan2,
#fotoiklan3 {
  display: none;
}
</style>