<template>
  <div id="test" @scroll="handleScroll">
    <div class="box">
      <b-field grouped group-multiline>
        <!-- <div class="control">
          <b-taglist attached>
            <b-tag type="is-warning">Tukang</b-tag>
            <b-tag type="is-dark">{{ $route.params.tukang }}</b-tag>
          </b-taglist>
        </div>

        <div class="control">
          <b-taglist attached>
            <b-tag type="is-warning">Lokasi</b-tag>
            <b-tag type="is-dark">{{ $route.params.kawasan }}</b-tag>
          </b-taglist>
        </div> -->

        <div class="control">
          <b-taglist attached>
            <b-tag type="is-warning">Jumlah Iklan</b-tag>
            <b-tag type="is-dark">{{ resultCount }}</b-tag>
          </b-taglist>
        </div>
      </b-field>
    </div>
    <template>
      <div class="columns is-centered" style="margin:0;margin-bottom: 50px;">
        <div class="column is-one-third ">
          <b-notification
            v-if="noResult || showAllListing"
            type="is-warning"
            has-icon
            aria-close-label="Close notification"
            :closable="false"
            icon="kiss-beam"
            icon-pack="fa"
          >
            <strong>Alamak!</strong><br />Tiada tukang dijumpai. Kami akan
            berusaha untuk menambah lebih ramai tukang berkebolehan dari semasa
            ke semasa.

            <br />
            <br />
            <b-button
              icon-left="chevron-circle-left"
              icon-pack="fa"
              size="is-small"
              type=" is-dark"
              label="Semula"
              @click="
                $router.push({
                  name: 'Home',
                })
              "
            />
            <br />
          </b-notification>
          <div class="card" v-if="isLoading">
            <div class="card-content">
              <div class="card-image">
                <figure>
                  <b-skeleton height="300px"></b-skeleton>
                  <b-skeleton width="20%"></b-skeleton>
                  <b-skeleton width="40%"></b-skeleton>
                  <b-skeleton width="80%"></b-skeleton>
                </figure>
              </div>
            </div>
          </div>
          <div
            v-for="(post, index) in posts"
            :key="index"
            class="card"
            :class="post.id"
          >
            <ShowcasePost :post="post" :index="index" />
            <hr />
          </div>
          <b-progress v-if="isLoading"></b-progress>
          <b-button
            v-if="lastVisible !== undefined"
            @click="getNextListing()"
            size="is-small"
            expanded
            outlined
            rounded
            >Lagi...</b-button
          >
        </div>
      </div>
    </template>
    <Footer :name2="kawasan" :name="tukang" :name3="bandar"></Footer>
  </div>
</template>

<script>
import firebase from "firebase";
//import VClamp from "vue-clamp";
import Footer from "./Footer.vue";
import ShowcasePost from "./ShowcasePost.vue";

export default {
  components: {
    //VClamp,
    Footer,
    ShowcasePost,
  },

  data: function() {
    return {
      posts: [],
      resultCount: 0,
      noResult: false,
      isLoading: true,
      maxlines: 5,
      lastVisible: undefined,
      lastVisible2: undefined,
      hasScrolledToBottom: false,
      rowLimit: 20,
      showMeta: false,
      showAllListing: false,
    };
  },
  props: ["kawasan", "tukang", "bandar", "loggedUserID"],
  computed: {
    kawasanDanTukang() {
      return `${this.kawasan}|${this.tukang}`;
    },
  },
  watch: {
    kawasanDanTukang: function() {
      //this.getListing();
    },
    resultCount: function(newVal, oldVal) {
      console.log(newVal, oldVal);
    },
    $route() {
      // TODO: react to navigation event.
      // params cotains the current route parameters
      this.getListing();
    },
  },
  methods: {
    comms(option, num) {
      switch (option) {
        case "call":
          window.location.href = "tel:" + num;
          break;
        case "whatsapp":
          window.location.href = "https://api.whatsapp.com/send/?phone=6" + num;
          break;
        case "sms":
          window.location.href = "sms:" + num;
          break;
        case "web":
          window.location.href = num;
          break;
      }
    },
    scrollToElement(options) {
      console.log("USER:" + this.loggedUserID);
      console.log(this.$el.getElementsByClassName(this.loggedUserID));
      if (this.$el.getElementsByClassName(this.loggedUserID).length > 0) {
        console.log("Scrolll");
        this.$el
          .getElementsByClassName(this.loggedUserID)[0]
          .scrollIntoView(options);
      } else {
        console.log(this.$el.getElementsByClassName(this.loggedUserID));
        console.log(this.$el.getElementsByClassName(this.loggedUserID).length);
      }
    },
    showAll: function(id) {
      console.log(this.$refs["desc" + id].maxlines);
      if (this.maxlines == 5) {
        this.maxlines = 1000;
      } else {
        this.maxlines = 5;
      }
    },
    handleScroll: function() {
      // console.log("scroll")

      // console.log(document.getElementById('test').scrollHeight)
      // //console.log(document.getElementById('test').scrollTop + document.getElementById('test').offsetHeight)
      // console.log(window.scrollY)
      // console.log(window.innerHeight)
      // console.log((window.scrollY + window.innerHeight)-document.getElementById('test').scrollHeight )
      //console.log(document.getElementById('test').offsetHeight + document.getElementById('test').scrollTop)
      if (document.getElementById("test") === null) {
        return;
      }
      if (
        window.scrollY + window.innerHeight >=
          document.getElementById("test").scrollHeight &&
        !this.hasScrolledToBottom
      ) {
        console.log("BOTTOM");
        console.log("BBBBBB--: " + this.isLoading);
        this.hasScrolledToBottom = true;

        if (this.tukang.match(/semua/gi)) {
          this.getNextAllListing();
        } else {
          this.getNextListing();
        }
      }
    },
    getNextListing: async function() {
      this.isLoading = true;
      if (this.lastVisible === undefined) {
        return;
      }
      let bandar = "";
      let bandarOperator = "==";
      let query;
      let query2;

      let collRef = firebase.firestore().collection("posts");
      if (this.bandar === "" || this.bandar.match(/seluruh/gi)) {
        bandarOperator = "!=";
      } else {
        bandar = this.bandar;
      }

      if (this.kawasan === "" || this.kawasan.match(/seluruh/gi)) {
        query = collRef
          .where("tukang", "==", this.tukang)
          .where("lokasi", "!=", false);
      } else {
        query = collRef
          .where("lokasi", "==", this.kawasan)
          .where("tukang", "==", this.tukang)
          .where("bandar", bandarOperator, bandar);
      }

      query2 = collRef
        .where("lokasi", "==", "Seluruh Malaysia")
        .where("tukang", "==", this.tukang);
      try {
        await query
          .where("active", "==", true)
          .startAfter(this.lastVisible)
          .limit(this.rowLimit)
          .get()
          .then((querySnapshot) => {
            var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            console.log(lastVisible);
            this.lastVisible = lastVisible;
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              // console.log(doc.id, " => ", doc.data());
              this.resultCount++;
              let post = doc.data();
              post.email = null;
              post.id = doc.id;
              console.log(post);

              this.posts.push(post);
            });

            this.hasScrolledToBottom = false;
            console.log("TOTAL RSULT" + this.resultCount);
          });
      } catch (error) {
        //console.log(error);
      }

      ////

      if (
        !this.kawasan.match(
          /seluruh/gi && this.kawasan.match(/seluruh/gi) !== null
        )
      ) {
        try {
          await query2
            .where("active", "==", true)
            .startAfter(this.lastVisible2)
            .limit(this.rowLimit)
            .get()
            .then((querySnapshot) => {
              var lastVisible =
                querySnapshot.docs[querySnapshot.docs.length - 1];
              console.log(lastVisible);
              this.lastVisible2 = lastVisible;
              querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());
                this.resultCount++;
                let post = doc.data();
                post.email = null;
                post.id = doc.id;
                console.log(post);

                this.posts.push(post);
              });
              this.hasScrolledToBottom = false;
              console.log("TOTAL RSULT" + this.resultCount);
            });
        } catch (error) {
          //
        }
      }
      this.isLoading = false;
      console.log("------->" + this.resultCount);
      console.log("------->" + this.isLoading);
      // if (this.resultCount == 0) {
      //   this.noResult = true;
      // } else {
      //   this.noResult = false;
      // }
    },
    getNextAllListing: async function() {
      let query = firebase.firestore().collection("posts");
      this.isLoading = true;
      try {
        await query
          .where("active", "==", true)
          .startAfter(this.lastVisible_forAll)
          .limit(this.rowLimit)
          .get()
          .then((querySnapshot) => {
            var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            console.log(lastVisible);
            this.lastVisible_forAll = lastVisible;
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              // console.log(doc.id, " => ", doc.data());
              this.resultCount++;
              let post = doc.data();
              post.email = null;
              post.id = doc.id;
              console.log(post);

              this.posts.push(post);
            });
            this.hasScrolledToBottom = false;
            console.log("TOTAL RSULT" + this.resultCount);
          });
      } catch (error) {
        //console.log(error);
      }
      this.isLoading = false;
      console.log("------->" + this.resultCount);
      console.log("------->" + this.isLoading);
      // if (this.resultCount == 0) {
      //   this.noResult = true;
      // } else {
      //   this.noResult = false;
      // }
    },
    getAllListing: async function() {
      let query = firebase.firestore().collection("posts");
      await query
        .where("active", "==", true)
        .limit(this.rowLimit)
        .get()
        .then((querySnapshot) => {
          var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          console.log(lastVisible);
          this.lastVisible_forAll = lastVisible;
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            this.resultCount++;
            let post = doc.data();
            post.email = null;
            post.id = doc.id;
            console.log(post);

            this.posts.push(post);
          });
          this.hasScrolledToBottom = false;
          console.log("TOTAL RSULT" + this.resultCount);
        });
      this.isLoading = false;
      console.log("------->" + this.resultCount);
      console.log("------->" + this.isLoading);
      if (this.resultCount == 0) {
        this.noResult = true;
      } else {
        this.noResult = false;
      }
    },
    getListing: async function() {
      this.posts = [];
      this.resultCount = 0;
      this.noResult = false;
      this.isLoading = true;
      let bandar = "";
      let bandarOperator = "==";
      let query;
      let query2;

      let collRef = firebase.firestore().collection("posts");
      if (this.bandar === "" || this.bandar.match(/seluruh/gi)) {
        bandarOperator = "!=";
      } else {
        bandar = this.bandar;
      }

      if (this.kawasan === "" || this.kawasan.match(/seluruh/gi)) {
        query = collRef
          .where("tukang", "==", this.tukang)
          .where("lokasi", "!=", false);
      } else {
        query = collRef
          .where("lokasi", "==", this.kawasan)
          .where("tukang", "==", this.tukang)
          .where("bandar", bandarOperator, bandar);
      }

      query2 = collRef
        .where("lokasi", "==", "Seluruh Malaysia")
        .where("tukang", "==", this.tukang);

      await query
        .where("active", "==", true)
        .limit(this.rowLimit)
        .get()
        .then((querySnapshot) => {
          var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
          console.log(lastVisible);
          this.lastVisible = lastVisible;
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            this.resultCount++;
            let post = doc.data();
            post.email = null;
            post.id = doc.id;
            console.log(post);

            this.posts.push(post);
          });
        });

      ///

      if (!this.kawasan.match(/seluruh/gi)) {
        await query2
          .where("active", "==", true)
          .limit(this.rowLimit)
          .get()
          .then((querySnapshot) => {
            var lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
            this.lastVisible2 = lastVisible;
            querySnapshot.forEach((doc) => {
              // doc.data() is never undefined for query doc snapshots
              // console.log(doc.id, " => ", doc.data());
              this.resultCount++;
              let post = doc.data();
              post.email = null;
              post.id = doc.id;
              console.log(post);

              this.posts.push(post);
            });
            this.hasScrolledToBottom = false;
            console.log("TOTAL RSULT" + this.resultCount);
          });
      }
      this.isLoading = false;
      console.log("1------->" + this.posts.length);
      console.log("1------->" + this.isLoading);
      if (this.resultCount == 0) {
        this.noResult = true;
        this.showAllListing = true;
        this.getAllListing();
      } else {
        this.noResult = false;
      }
    },
  },
  mounted() {
    document.addEventListener("scroll", this.handleScroll);
    console.log(this.kawasan);
    console.log(this.tukang);
    console.log(this.bandar);
    if (this.kawasan.length == 0 || this.tukang.length == 0) {
      this.$router.push({
        name: "Home",
      });
    }
    if (this.tukang.match(/semua/gi)) {
      this.getAllListing();
    } else {
      this.getListing();
    }

    this.$nextTick(() => {
      // The whole view is rendered, so I can safely access or query
      // the DOM. ¯\_(ツ)_/¯
      this.scrollToElement({ behavior: "smooth" });
    });
  },
};
</script>
