<template>
  <div>
    <section class="hero is-small">
      <div class="hero-body">
        <p class="title">Log Masuk</p>
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <div class="container">
              <section id="firebaseui-auth-container"></section>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "firebase";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

export default {
  mounted: function() {
    var ui =
      firebaseui.auth.AuthUI.getInstance() ||
      new firebaseui.auth.AuthUI(firebase.auth());
    ui.start("#firebaseui-auth-container", {

      signInOptions: [
        {
          provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
          recaptchaParameters: {
            type: "image", // 'audio'
            size: "compact", // 'invisible' or 'compact'
            badge: "bottomleft", //' bottomright' or 'inline' applies to invisible.
          },
          defaultCountry: "MY", // Set default country to the United Kingdom (+44).
          whitelistedCountries: ["+60"],
        },
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      signInSuccessUrl: "/update",
    });
  },
};
</script>
