<template>
  <div id="test">
    <template>
      <div class="columns is-centered" style="margin:0;margin-bottom: 50px;">
        <div class="column is-one-third ">
          <b-notification
            v-if="hasNoPost"
            type="is-success"
            has-icon
            aria-close-label="Close notification"
            :closable="false"
            icon="kiss-beam"
            icon-pack="fa"
          >
            <strong>Senarai pendek anda kosong</strong><br /><br />
            <b-button
              icon-left="chevron-circle-left"
              icon-pack="fa"
              size="is-small"
              type=" is-dark"
              label="Home"
              @click="
                $router.push({
                  name: 'Home',
                })
              "
            />
            <br />
          </b-notification>
          <div class="card" v-if="isLoading">
            <div class="card-content">
              <div class="card-image">
                <figure>
                  <b-skeleton height="300px"></b-skeleton>
                  <b-skeleton width="20%"></b-skeleton>
                  <b-skeleton width="40%"></b-skeleton>
                  <b-skeleton width="80%"></b-skeleton>
                </figure>
              </div>
            </div>
          </div>
          <div v-for="(post, index) in posts" :key="index" class="card" :class="post.id">
            <ShowcasePost :post="post" :index="index" :shortlisted="true" />
            <hr />
          </div>
        </div>
      </div>
    </template>
    <Footer :name2="kawasan" :name="tukang" :name3="bandar"></Footer>
  </div>
</template>

<script>
import firebase from "firebase";
import Footer from "./Footer.vue";
import ShowcasePost from "./ShowcasePost.vue";
import { eventBus } from "../main";

export default {
  components: {
    Footer,
    ShowcasePost,
  },

  data: function () {
    return {
      tukang: "",
      bandar: "",
      kawasan: "",
      posts: [],
      resultCount: 0,
      noResult: false,
      isLoading: true,
      maxlines: 5,
      rowLimit: 20,
      hasNoPost: false
    };
  },
  computed: {
    kawasanDanTukang() {
      return `${this.kawasan}|${this.tukang}`;
    },
  },
  watch: {
    kawasanDanTukang: function () {
      //this.getListing();
    },
    resultCount: function (newVal, oldVal) {
      console.log(newVal, oldVal);
    },
    $route() {
      // TODO: react to navigation event.
      // params cotains the current route parameters
      this.getListing();
    },
  },
  methods: {
    comms(option, num) {
      switch (option) {
        case "call":
          window.location.href = "tel:" + num;
          break;
        case "whatsapp":
          window.location.href = "https://api.whatsapp.com/send/?phone=6" + num;
          break;
        case "sms":
          window.location.href = "sms:" + num;
          break;
        case "web":
          window.location.href = num;
          break;
      }
    },
    showAll: function (id) {
      console.log(this.$refs["desc" + id].maxlines);
      if (this.maxlines == 5) {
        this.maxlines = 1000;
      } else {
        this.maxlines = 5;
      }
    },
    getAllListing: async function (items) {
      let query = firebase.firestore().collection("posts");
      await query
        .where("active", "==", true)
        .where("__name__", "in", items)
        .limit(this.rowLimit)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            this.resultCount++;
            let post = doc.data();
            post.email = null;
            post.id = doc.id;
            console.log(post);

            this.posts.push(post);
          });
          console.log("TOTAL RSULT" + this.resultCount);
          if (this.resultCount < 1){
            this.hasNoPost = true
          }
        });
      this.isLoading = false;
      console.log("------->" + this.resultCount);
      console.log("------->" + this.isLoading);
      if (this.resultCount == 0) {
        this.noResult = true;
      } else {
        this.noResult = false;
      }
    }
  },
  mounted() {
    this.bandar = ""
    this.kawasan = ""

    if (localStorage.shortlisted) {
      let items = JSON.parse(localStorage.shortlisted)
      if (items.length > 0) {
        this.getAllListing(items);
      }else{
        this.hasNoPost = true
        this.isLoading = false;
      }

    }

    eventBus.$on('removeLikedItems', (item) => {
      var newPosts = this.posts.filter(function (el) {
        return el.id != item
      });
      this.posts = newPosts
      if (newPosts.length < 1){
        this.hasNoPost = true
      }
    });

  }
};
</script>
