<template>
  <div>
    <section class="hero is-small">
      <div class="hero-body">
        <p v-if="isNewUser" class="title">Daftar Iklan</p>
        <p v-else class="title">Kemaskini Iklan</p>
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <div class="container">
              <b-message
                v-if="!newPost.active && !isNewPost"
                title="Iklan ini tidak aktif"
                type="is-danger"
                :closable="false"
                has-icon
                icon="exclamation-circle"
                icon-pack="fas"
              >
                Iklan ini telah diset tidak aktif.
                <b-button
                  icon-left="trash-alt"
                  size="is-small"
                  icon-pack="fa"
                  type="is-success"
                  native-type="button"
                  @click="unsuppressPost()"
                >
                  <strong>Aktif Semula Iklan</strong>
                </b-button>
              </b-message>
              <b-message
                v-if="!userEmailVerfied"
                title="Akaun anda masih belum disahkan"
                type="is-danger"
                :closable="false"
                has-icon
                icon="exclamation-circle"
                icon-pack="fas"
              >
                Anda perlu mengesahkan emel anda terlebih dahulu.
                <b-button
                  icon-left="paper-plane"
                  size="is-small"
                  icon-pack="fa"
                  type="is-link"
                  native-type="button"
                  @click="sendEmailVerification()"
                >
                  <strong>Hantar Emel Pengesahan</strong>
                </b-button>
              </b-message>
              <br />
              <ValidationObserver tag="form" ref="observer">
                <form @submit.prevent="createPost()">
                  <validation-provider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      label="Kategori Tukang *"
                      label-position="on-border"
                      :type="{
                        'is-danger': errors[0],
                        'is-success': valid,
                      }"
                      :message="errors"
                    >
                      <b-select v-model="newPost.tukang" expanded>
                        <option
                          v-for="category in $sharedData.tukangtemp"
                          v-bind:key="category.name"
                        >
                          {{ category.name }}
                        </option>
                      </b-select>
                    </b-field>
                    <!-- <b-field
                      v-if="newPost.tukang == 'Lain-lain...'"
                      
                      label="Tukang *"
                      label-position="on-border"
                    >
                      <b-input v-model="newCategory" maxlength="30"></b-input>
                    </b-field> -->
                  </validation-provider>
                  <h6>
                    Tak jumpa tukang yang anda perlukan? Cadangkan
                    <a @click="$router.push('/cadangan')">
                      <b-tag
                        icon-left="search"
                        icon-pack="fa"
                        size="is-small"
                        type="is-link"
                        ><strong>di sini</strong></b-tag
                      >
                    </a>
                  </h6>
                  <br />

                  <validation-provider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      label="Negeri *"
                      label-position="on-border"
                      :type="{
                        'is-danger': errors[0],
                        'is-success': valid,
                      }"
                      :message="errors"
                    >
                      <b-select v-model="newPost.lokasi" expanded>
                        <!-- <option disabled value=[]>Pilih satu ...</option> -->
                        <option
                          v-for="state in $sharedData.states"
                          v-bind:key="state"
                        >
                          {{ state }}
                        </option>
                      </b-select>
                    </b-field>
                  </validation-provider>
                  <br />
                  <validation-provider
                    v-if="showBandar"
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      v-if="showBandar"
                      label="Bandar *"
                      label-position="on-border"
                      :type="{
                        'is-danger': errors[0],
                        'is-success': valid,
                      }"
                      :message="errors"
                    >
                      <b-select v-model="newPost.bandar" expanded>
                        <!-- <option disabled value=[]>Pilih satu ...</option> -->
                        <option
                          v-for="bandar in $sharedData[newPost.lokasi]"
                          v-bind:key="bandar"
                        >
                          {{ bandar }}
                        </option>
                      </b-select>
                    </b-field>
                  </validation-provider>

                  <br />
                  <br />

                  <b-field
                    label="Foto Iklan (jika ada)"
                    label-position="on-border"
                  >
                    <div class="columns is-mobile">
                      <div class="column has-text-centered">
                        <image-uploader
                          id="fotoiklan1"
                          :preview="true"
                          :className="[
                            'fotoiklan1',
                            { 'fotoiklan1--loaded': hasImage1 },
                          ]"
                          :debug="0"
                          :autoRotate="true"
                          outputFormat="string"
                          :maxHeight="500"
                          :maxWidth="500"
                          accept="image/*"
                          @input="setImage1"
                        >
                          <label for="fotoiklan1" slot="upload-label">
                            <figure>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                              >
                                <path
                                  class="path1"
                                  d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                                ></path>
                              </svg>
                            </figure>
                            <b-tag type="is-warning" class="upload-caption">{{
                              hasImage1 ? "Tukar" : "Foto 1"
                            }}</b-tag>
                          </label>
                        </image-uploader>
                        <b-button
                          v-if="hasImage1"
                          size="is-small"
                          type="is-danger"
                          @click="clearImage1"
                          >Buang</b-button
                        >
                        <!-- {{newPost.fotoBase64[0].}} -->
                      </div>
                      <div class="column has-text-centered">
                        <image-uploader
                          id="fotoiklan2"
                          :preview="true"
                          :className="[
                            'fotoiklan2',
                            { 'fotoiklan2--loaded': hasImage2 },
                          ]"
                          :debug="0"
                          :autoRotate="true"
                          outputFormat="string"
                          :maxHeight="500"
                          :maxWidth="500"
                          accept="image/*"
                          @input="setImage2"
                        >
                          <label for="fotoiklan2" slot="upload-label">
                            <figure>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                              >
                                <path
                                  class="path1"
                                  d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                                ></path>
                              </svg>
                            </figure>
                            <b-tag type="is-warning" class="upload-caption">{{
                              hasImage2 ? "Tukar" : "Foto 2"
                            }}</b-tag>
                          </label>
                        </image-uploader>
                        <b-button
                          v-if="hasImage2"
                          size="is-small"
                          type="is-danger"
                          @click="clearImage2"
                          >Buang</b-button
                        >
                      </div>
                      <div class="column has-text-centered">
                        <image-uploader
                          id="fotoiklan3"
                          :preview="true"
                          :className="[
                            'fotoiklan3',
                            { 'fotoiklan3--loaded': hasImage3 },
                          ]"
                          :debug="0"
                          :autoRotate="true"
                          outputFormat="string"
                          :maxHeight="500"
                          :maxWidth="500"
                          accept="image/*"
                          @input="setImage3"
                        >
                          <label for="fotoiklan3" slot="upload-label">
                            <figure>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                              >
                                <path
                                  class="path1"
                                  d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                                ></path>
                              </svg>
                            </figure>
                            <b-tag type="is-warning" class="upload-caption">{{
                              hasImage3 ? "Tukar" : "Foto 3"
                            }}</b-tag>
                          </label>
                        </image-uploader>
                        <b-button
                          v-if="hasImage3"
                          size="is-small"
                          type="is-danger"
                          @click="clearImage3"
                          >Buang</b-button
                        >
                      </div>
                    </div>
                  </b-field>

                  <br />

                  <!-- <validation-provider
                    rules="required|email"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      label="Email *"
                      style="display:none;"
                      label-position="on-border"
                      :type="{
                        'is-danger': errors[0],
                        'is-success': valid,
                      }"
                      :message="errors"
                    >
                      <b-input v-model="newPost.email" type="email"> </b-input>
                    </b-field>
                  </validation-provider> -->
                  <br />
                  <validation-provider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      label="Deskripsi Ringkas *"
                      label-position="on-border"
                      :type="{
                        'is-danger': errors[0],
                        'is-success': valid,
                      }"
                      :message="errors"
                    >
                      <b-input
                        maxlength="700"
                        type="textarea"
                        v-model="newPost.desc"
                        placeholder="E.g., Saya menyediakan perkhidmatan tukang rumah dan kerja2 berkaitan dengannya"
                      ></b-input>
                    </b-field>
                  </validation-provider>
                  <br />
                  <b-field
                    label="Senarai Perkhidmatan Yang Ditawarkan"
                    label-position="on-border"
                  >
                    <b-taginput
                      maxtags="5"
                      v-model="newPost.khidmat"
                      type="is-info"
                      ellipsis
                      icon="wrench"
                      icon-pack="fa"
                      placeholder="E.g., Servis cucian aircond"
                    >
                    </b-taginput>
                  </b-field>
                  <br />
                  <b-field
                    label="Nama Perniagaan (jika ada)"
                    label-position="on-border"
                  >
                    <b-input
                      v-model="newPost.namaPerniagaan"
                      maxlength="50"
                    ></b-input>
                  </b-field>

                  <b-field
                    label="No. SSM (jika ada)"
                    label-position="on-border"
                  >
                    <b-input v-model="newPost.SSM" maxlength="30"></b-input>
                  </b-field>

                  <validation-provider
                    rules="required|max:12|min:10|integer"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      label="Nombor Telefon * (untuk pelanggan menghubungi anda)"
                      label-position="on-border"
                      :type="{
                        'is-danger': errors[0],
                        'is-success': valid,
                      }"
                      :message="errors"
                    >
                      <b-input v-model="newPost.phone" maxlength="12"></b-input>
                    </b-field>
                  </validation-provider>

                  <br />
                  <validation-provider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      label="Mod Perhubungan *"
                      label-position="on-border"
                    >
                    </b-field>
                    <br />
                    <b-field
                      :type="{
                        'is-danger': errors[0],
                        'is-success': valid,
                      }"
                      :message="errors"
                    >
                      <b-checkbox
                        v-model="newPost.comms"
                        native-value="sms"
                        type="is-info"
                      >
                        SMS
                      </b-checkbox>

                      <b-checkbox
                        v-model="newPost.comms"
                        native-value="whatsapp"
                        type="is-success"
                      >
                        Whatsapp
                      </b-checkbox>

                      <b-checkbox
                        v-model="newPost.comms"
                        native-value="call"
                        type="is-danger"
                      >
                        Call
                      </b-checkbox>
                    </b-field>
                  </validation-provider>
                  <br />
                  <validation-provider
                    :rules="{ required, regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ }"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      label="Website (jika ada)"
                      label-position="on-border"
                      :type="{
                        'is-danger': errors[0],
                        'is-success': valid,
                      }"
                      :message="errors"
                    >
                      <b-input
                        v-model="newPost.website"
                        maxlength="100"
                      ></b-input>
                    </b-field>
                  </validation-provider>
                  <hr />

                  <validation-provider
                    rules="required"
                    v-slot="{ errors, valid }"
                  >
                    <b-field
                      :type="{
                        'is-danger': errors[0],
                        'is-success': valid,
                      }"
                      :message="errors"
                    >
                      <b-checkbox v-model="newPost.agreed" type="is-info">
                        Saya setuju dengan
                        <a @click="showPDPA()">Terma dan Syarat</a>
                      </b-checkbox>
                    </b-field>
                  </validation-provider>
                  <!-- <b-button
                    label="Launch image modal"
                    type="is-primary"
                    size="is-medium"
                    @click="isModalActive = true"
                  /> -->
                  <b-modal v-model="isModalActive">
                    <div class="container has-text-centered">
                      <b-button
                        size="is-large"
                        icon-pack="fa"
                        type="is-warning"
                        style="height:200px;"
                      >
                        <b-icon icon="party" pack="fas"></b-icon><br />
                        <strong>Pakej 1 Bulan</strong>
                        <br />
                        <small>asddsadsdasds</small>
                      </b-button>
                      <!-- <b-button
                        size="is-large"
                        icon-pack="fa"
                        type="is-info"
                        style="height:200px;"
                      >
                        <strong>Pakej 2 Bulan</strong>
                        <br>
                        <small>Percuma!</small>
                      </b-button> -->
                    </div>
                  </b-modal>

                  <b-button
                    :disabled="!newPost.active && !isNewPost"
                    icon-left="paper-plane"
                    size="is-medium"
                    icon-pack="fa"
                    type="is-warning"
                    native-type="submit"
                  >
                    <strong>{{ isNewPost ? "Daftar" : "Kemaskini" }}</strong>
                  </b-button>
                  <br />
                  <br />

                  <b-button
                    :disabled="!newPost.active"
                    icon-left="pause"
                    size="is-small"
                    icon-pack="fa"
                    type="is-danger"
                    native-type="button"
                    @click="suppressPost()"
                  >
                    <strong>Henti Iklan</strong>
                  </b-button>
                  <b-button
                    :disabled="newPost.active || isNewPost"
                    icon-left="play"
                    size="is-small"
                    icon-pack="fa"
                    type="is-success"
                    native-type="button"
                    @click="unsuppressPost()"
                  >
                    <strong>Aktif Semula Iklan</strong>
                  </b-button>
                  <b-button
                    :disabled="isNewPost"
                    icon-left="trash-alt"
                    size="is-small"
                    icon-pack="fa"
                    type="is-dark"
                    native-type="button"
                    @click="deletePost()"
                  >
                    <strong>Buang Iklan</strong>
                  </b-button>
                  <br />
                  <br />
                  <br />
                </form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "firebase";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  required,
  length,
  max,
  min,
  integer,
  ext,
  email,
  min_value,
  regex,
} from "vee-validate/dist/rules";
import ImageUploader from "vue-image-upload-resize";

extend('regex', regex)

extend("required", {
  ...required,
  message: "Ruangan ini adalah wajib diisi",
});

extend("length", {
  ...length,
  message: "Tidak mencapai minimum pilihan",
});

extend("max", {
  ...max,
  message: "Melebihi maksimum aksara",
});

extend("min", {
  ...min,
  message: "Tidak mencapai minimum aksara",
});

extend("integer", {
  ...integer,
  message: "Bukan dijit",
});

extend("min_value", {
  ...min_value,
  message: "reCAPTCHA perlu diselesaikan",
});

extend("ext", {
  ...ext,
  message: "Format gambar hendaklah JPEG atau PNG ",
});

extend("email", {
  ...email,
  message: "Tidak menepati kriteria email",
});


export default {
  data() {
    return {
      newPost: {
        email: "",
        phone: "",
        fotoBase64: [],
        comms: [],
        agreed: "",
        tukang: "",
        lokasi: [],
        bandar: [],
        khidmat: [],
        namaPerniagaan: "",
        SSM: "",
        desc: "",
        active: false,
        created: "",
        updated: "",
        website: "",
      },
      newCategory: "",
      lokasi: [],
      tukang: [],
      isNewUser: true,
      isNewPost: true,
      userUID: "",
      userPhone: "",
      userEmail: "",
      userDisplayName: "",
      userEmailVerfied: true,

      hasImage1: false,
      image1: null,
      hasImage2: false,
      image2: null,
      hasImage3: false,
      image3: null,

      isModalActive: false,
      showBandar: true,
      selectedBandar: "",
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    ImageUploader,
  },
  computed: {
    bandar2: function() {
      return this.newPost.lokasi;
    },
  },
  watch: {
    bandar2: function(newVal) {
      if (newVal.match(/seluruh/gi)) {
        console.log("ahahh");
        this.newPost.bandar = "Semua Negeri";
        this.showBandar = false;
      } else {
        this.newPost.bandar = this.selectedBandar;
        this.selectedBandar = "";
        this.showBandar = true;
      }
    },
  },
  methods: {
    setImage1: function(file) {
      this.hasImage1 = true;
      this.newPost.fotoBase64[0] = file;
      if (document.getElementById("fotoiklanlama1")) {
        document.getElementById("fotoiklanlama1").remove();
      }
    },
    setImage2: function(file) {
      this.hasImage2 = true;
      this.newPost.fotoBase64[1] = file;
      if (document.getElementById("fotoiklanlama2")) {
        document.getElementById("fotoiklanlama2").remove();
      }
    },
    setImage3: function(file) {
      this.hasImage3 = true;
      this.newPost.fotoBase64[2] = file;
      if (document.getElementById("fotoiklanlama3")) {
        document.getElementById("fotoiklanlama3").remove();
      }
    },
    clearImage1: function() {
      this.hasImage1 = false;
      this.newPost.fotoBase64.splice(0, 1);
      if (document.getElementById("fotoiklanlama1")) {
        document.getElementById("fotoiklanlama1").remove();
      }
    },
    clearImage2: function() {
      this.hasImage2 = false;
      this.newPost.fotoBase64.splice(1, 1);
      if (document.getElementById("fotoiklanlama2")) {
        document.getElementById("fotoiklanlama2").remove();
      }
    },
    clearImage3: function() {
      this.hasImage3 = false;
      this.newPost.fotoBase64.splice(2, 1);
      if (document.getElementById("fotoiklanlama3")) {
        document.getElementById("fotoiklanlama3").remove();
      }
    },

    deletePost() {
      this.$buefy.snackbar.open({
        indefinite: true,
        message: "Anda pasti untuk buang iklan? ",
        cancelText: "Batal",
        actionText: "Ya",
        onAction: () => {
          firebase
            .firestore()
            .collection("posts")
            .doc(this.userUID)
            .delete()
            .then(() => {
              this.clearImage1();
              this.clearImage2();
              this.clearImage3();
              this.newPost.active = false;
              this.isNewPost = true;
              this.isNewUser = true;
              this.newPost = {
                email: "",
                phone: "",
                fotoBase64: [],
                comms: [],
                agreed: "",
                tukang: "",
                lokasi: [],
                khidmat: [],
                namaPerniagaan: "",
                SSM: "",
                desc: "",
                active: false,
              };

              this.$buefy.toast.open({
                type: "is-success",
                message: "Iklan telah dibuang",
                queue: false,
              });

              console.log(this.newPost);
            })
            .catch((error) => {
              console.log(error);
            });
        },
      });
    },
    suppressPost() {
      this.$buefy.snackbar.open({
        indefinite: true,
        message: "Anda pasti untuk henti iklan? ",
        cancelText: "Batal",
        actionText: "Ya",
        onAction: () => {
          firebase
            .firestore()
            .collection("posts")
            .doc(this.userUID)
            .update({ active: false })
            .then(() => {
              this.newPost.active = false;
              this.$buefy.toast.open({
                type: "is-success",
                message: "Iklan dihentikan",
                queue: false,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        },
      });
    },
    unsuppressPost() {
      this.$buefy.snackbar.open({
        indefinite: true,
        message: "Anda pasti untuk aktifkan semula iklan? ",
        cancelText: "Batal",
        actionText: "Ya",
        onAction: () => {
          firebase
            .firestore()
            .collection("posts")
            .doc(this.userUID)
            .update({ active: true })
            .then(() => {
              this.newPost.active = true;
              this.$buefy.toast.open({
                type: "is-success",
                message: "Iklan telah diaktifkan semula",
                queue: false,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        },
      });
    },
    getPost() {
      let defaultData = this.newPost;
      var docRef = firebase
        .firestore()
        .collection("posts")
        .doc(this.userUID);

      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.isNewUser = false;
            this.newPost = doc.data();
            this.isNewPost = false;

            this.selectedBandar = this.newPost.bandar;

            for (const [key, value] of Object.entries(defaultData)) {
              if (!(key in this.newPost)) {
                this.newPost[key] = value;
              }
            }

            console.log(this.newPost);

            if (typeof this.newPost.fotoBase64[0] !== "undefined") {
              var target1 = document.getElementById("fotoiklan1");
              var img1 = document.createElement("img");
              img1.src = this.newPost.fotoBase64[0];
              this.hasImage1 = true;
              img1.id = "fotoiklanlama1";
              target1.parentNode.insertBefore(img1, target1);
            }
            if (typeof this.newPost.fotoBase64[1] !== "undefined") {
              var target2 = document.getElementById("fotoiklan2");
              var img2 = document.createElement("img");
              img2.src = this.newPost.fotoBase64[1];
              this.hasImage2 = true;
              img2.id = "fotoiklanlama2";
              target2.parentNode.insertBefore(img2, target2);
            }
            if (typeof this.newPost.fotoBase64[2] !== "undefined") {
              var target3 = document.getElementById("fotoiklan3");
              var img3 = document.createElement("img");
              img3.src = this.newPost.fotoBase64[2];
              this.hasImage3 = true;
              img3.id = "fotoiklanlama3";
              target3.parentNode.insertBefore(img3, target3);
            }
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            this.isNewUser = true;

            this.isNewPost = true;
            if (this.userPhone) {
              this.newPost.phone = this.userPhone.substring(2);
            }

            if (this.userEmail !== "") {
              this.newPost.email = this.userEmail;
            }
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
    sendEmailVerification() {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          user.sendEmailVerification();
          console.log("sent email verification");
          this.$buefy.toast.open({
            type: "is-success",
            message:
              "Emel pengesahan telah dihantar. Sila semak inbox emel anda.",
            queue: false,
          });
        } else {
          console.log("logged out");
        }
      });
    },
    showPDPA() {
      this.$buefy.dialog.confirm({
        title: "Notis PDPA",
        message: `By submitting this Form, you hereby agree that CodePro Technologies may collect,
      obtain, store and process your personal data that you provide in this form
      for the purpose of receiving updates, news, promotional and marketing
      mails or materials from CodePro Technologies. You hereby give your consent to CodePro Technologies
      to:- Store and process your Personal Data; Disclose your Personal Data to
      the relevant governmental authorities or third parties where required by
      law or for legal purposes. In addition, your personal data may be
      transferred to any company within the CodePro Technologies which may involve sending
      your data to a location outside Malaysia. For the purpose of updating or
      correcting such data, you may at any time apply to the CodePro Technologies to have
      access to your personal data which are stored by CodePro Technologies. For the
      avoidance of doubt, Personal Data includes all data defined within the
      Personal Data Protection Act 2010 including all data you had disclosed to
      CodePro Technologies in this Form.`,
        canCancel: false,
        confirmText: "Kembali",
        type: "is-success",
      });
    },
    async createPost() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        console.log("failed");
        return;
      }
      console.log("mula iklan");
      this.newPost.active = true;
      if (this.isNewPost) {
        this.newPost.created = firebase.firestore.FieldValue.serverTimestamp();
      } else {
        this.newPost.updated = firebase.firestore.FieldValue.serverTimestamp();
      }

      console.log(this.newCategory);
      if (this.newCategory !== "") {
        this.newPost.tukang = this.newCategory;
      }
      if (!this.userEmailVerfied) {
        this.warnEmailNotVerified();
        return;
      }
      firebase
        .firestore()
        .collection("posts")
        .doc(this.userUID)
        .set(this.newPost)
        .then(() => {
          console.log("New post successfully created!");
          this.isNewPost = false;
          this.$buefy.dialog.alert({
            message: "🎉 Tahniah! Iklan anda telah berjaya disiarkan!",
            confirmText: "Lihat Iklan",
          });
          // send email to me
          firebase.firestore().collection('mail').add({
            to: 'zarulzakuan@gmail.com',
            message: {
              subject: 'New post has been updated',
              html: JSON.stringify(this.newPost, undefined, 4),
            },
          });
          this.$router.push({
            name: "Listing",
            params: {
              tukang: this.newPost.tukang,
              kawasan: this.newPost.lokasi,
              bandar: this.newPost.bandar,
              loggedUserID: this.userUID,
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    warnEmailNotVerified() {
      this.$buefy.dialog.alert({
        message:
          "Anda perlu mengesahkan emel anda terlebih dahulu. Klik pilihan di bawah dan semak emel pengesahan dari CariTukang.my di dalam inbox anda",
        cancelText: "Kembali",
        confirmText: "Hantar Emel Pengesahan",
        canCancel: true,
        onConfirm: () => this.sendEmailVerification(),
      });
    },
  },
  mounted: async function() {
    await firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // user.sendEmailVerification();
        this.userUID = user.uid;
        this.userPhone = user.phoneNumber;
        console.log(user.email);
        this.userEmail = user.email;

        this.getPost();
        console.log("logged in");

        // if user logged with email and not verified, flag it
        if (user.email !== null) {
          this.userEmailVerfied = user.emailVerified;
        }
      } else {
        console.log("logged out");
      }
    });

    if (!this.userEmailVerfied) {
      this.warnEmailNotVerified();
    }
  },
};
</script>

<style>
#fotoiklan1,
#fotoiklan2,
#fotoiklan3 {
  display: none;
}
</style>
