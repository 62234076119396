var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"hero is-small"},[_c('div',{staticClass:"hero-body"},[_c('p',{staticClass:"title"},[_vm._v("Cadangan")]),_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-three-quarters"},[_c('div',{staticClass:"container"},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitCadangan()}}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Jenis Cadangan","label-position":"on-border","type":{
                    'is-danger': errors[0],
                    'is-success': valid,
                  },"message":errors}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.cadangan.jenisCadangan),callback:function ($$v) {_vm.$set(_vm.cadangan, "jenisCadangan", $$v)},expression:"cadangan.jenisCadangan"}},[_c('option',{attrs:{"value":"tambah tukang"}},[_vm._v("Tambah Kategori Tukang")]),_c('option',{attrs:{"value":"lain-lain"}},[_vm._v("Lain-lain Cadangan")])])],1)]}}])}),(_vm.cadangan.jenisCadangan == 'tambah tukang')?[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{
                      'is-danger': errors[0],
                      'is-success': valid,
                    },"message":errors}},[_c('b-input',{attrs:{"placeholder":"Kategori tukang baru","type":"text"},model:{value:(_vm.cadangan.tukangBaru),callback:function ($$v) {_vm.$set(_vm.cadangan, "tukangBaru", $$v)},expression:"cadangan.tukangBaru"}})],1)]}}],null,false,360856344)})]:_vm._e(),(_vm.cadangan.jenisCadangan == 'lain-lain')?[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{
                      'is-danger': errors[0],
                      'is-success': valid,
                    },"message":errors}},[_c('b-input',{attrs:{"type":"textarea","maxlength":"1000","placeholder":"Nyatakan cadangan anda"},model:{value:(_vm.cadangan.lainlain),callback:function ($$v) {_vm.$set(_vm.cadangan, "lainlain", $$v)},expression:"cadangan.lainlain"}})],1)]}}],null,false,1540005812)})]:_vm._e(),_c('br'),_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Emel","label-position":"on-border","type":{
                    'is-danger': errors[0],
                    'is-success': valid,
                  },"message":errors}},[_c('b-input',{attrs:{"type":"email","icon-pack":"fas","icon":"envelope"},model:{value:(_vm.cadangan.email),callback:function ($$v) {_vm.$set(_vm.cadangan, "email", $$v)},expression:"cadangan.email"}})],1),_c('br')]}}])}),_c('b-button',{attrs:{"icon-left":"paper-plane","size":"is-medium","icon-pack":"fa","type":"is-warning","native-type":"submit"}},[_c('strong',[_vm._v("Hantar")])])],2)])],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }