<template>

  <div id="app">
    
    <Navbar />
    <router-view></router-view>
    <!-- <Footer v-else> </Footer> -->
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
// import FooterHome from "./components/FooterHome.vue";

// import HelloWorld from './components/HellKCoWorld.vue'

export default {
  name: "App",
  components: {
    // Home,
    // FooterHome,
    Navbar,
    // Showcase,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
</style>
