<template>
  <div>
    <section class="hero is-medium is-light">
      <div class="hero-body has-text-centered ">
        <div class="container has-text-centered" style="height: 20vh;">
          <img style="max-height:80px;" src="../assets/logo.png" />
          <br />
          <vue-typed-js style="display:block" :strings="trimmedTuakngList" :shuffle="true" :backDelay="1500"
            :backSpeed="20" :typeSpeed="40" :loop="true">
            <p style="font-size:5vh"><span class="typing"></span></p>
          </vue-typed-js>

          <br />
          <br />
        </div>
        <p class="title">Memacu Ekonomi Gig Rakyat Malaysia</p>
        <div class="container has-text-centered">
          <div class="field-body" style="display:inline-flex;">
            <b-autocomplete size="is-medium" position="is-right" v-model="name" placeholder="Tukang"
              :open-on-focus="true" :data="tukangList" @select="(option) => (selected = option)" field="name"
              icon="wrench" icon-pack="fas" :clearable="true">
            </b-autocomplete>
          </div>
        </div>

        <div class="container has-text-centered">
          <div class="field-body" style="display:inline-flex;">
            <b-autocomplete size="is-medium" v-model="name2" placeholder="Negeri" :open-on-focus="true"
              :data="lokasiList" icon="map-marker" icon-pack="fas" field="name2"
              @select="(option) => (selected = option)" :clearable="true">
            </b-autocomplete>
          </div>
        </div>

        <div class="container has-text-centered">
          <div class="field-body" style="display:inline-flex;">
            <b-autocomplete v-if="bandarList.length > 0" size="is-medium" v-model="name3" placeholder="Bandar"
              :open-on-focus="true" :data="bandarList" @select="(option) => (selected = option)" field="name3"
              icon="city" icon-pack="fas" :clearable="true">
            </b-autocomplete>
          </div>
        </div>

        <!-- <b-field position="is-centered">
          <b-autocomplete
            size="is-medium"
            v-model="name2"
            placeholder="Negeri"
            :open-on-focus="true"
            :data="lokasiList"
            icon="map-marker"
            icon-pack="fas"
            field="name2"
            @select="(option) => (selected = option)"
            :clearable="true"
          >
          </b-autocomplete>
          <b-autocomplete
            v-if="bandarList.length > 0"
            size="is-medium"
            v-model="name3"
            placeholder="Bandar"
            :open-on-focus="true"
            :data="bandarList"
            @select="(option) => (selected = option)"
            field="name3"
            icon="city"
            icon-pack="fas"
            :clearable="true"
          >
          </b-autocomplete>
        </b-field> -->

        <br />
        <b-button icon-left="search" icon-pack="fa" size="is-large" type="is-warning" v-on:click="findTukang()">
          <strong>Cari</strong>
        </b-button>
        <br>
        <b-button type="is-warning is-light" v-on:click="findAllTukang()"><strong>Lihat semua</strong></b-button>
        <br />
        <br />

        <p>
          Tak jumpa tukang yang anda perlukan? Cadangkan
          <a @click="$router.push('/cadangan')">
            <b-tag icon-left="search" icon-pack="fa" size="is-small" type="is-link"><strong>di sini</strong></b-tag>
          </a>
        </p>
        <br />
        <b-button icon-left="edit" icon-pack="fa" type="is-info" @click="$router.push('/update')">
          <strong>Iklankan Servis Anda Percuma!</strong>
        </b-button>
        <p>
          <b><number ref="number1" :from="100" :to="982" :format="theFormat" :duration="3" :delay="0"
            easing="Power1.easeOut" /></b> Tukang telah berdaftar!
        </p>
      </div>

    </section>
    <!-- <section class="hero">
      <div class="hero-body">
        <div
          v-for="(group, i) in tukangGroups"
          v-bind:key="i"
          class="columns is-mobile"
        >
          <div
            v-for="(tukang, j) in $sharedData.tukangtemp.slice(
              i * 4,
              (i + 1) * 4
            )"
            v-bind:key="j"
            class="column has-text-centered is-one-quarter"
          >
            <a href="#"
              ><img :src="tukang.photo" v-on:click="findTukang(tukang.name)"
            /></a>
            <p>
              <a href="#" v-on:click="findTukang(tukang.name)">
                {{ tukang.name }}
              </a>
              <br />
            </p>
          </div>
        </div>
      </div>
    </section> -->
    <!-- <section class="hero is-small is-dark">
      <div class="hero-body ">
        <p class="title">Top 10 Tukang</p>
        <div v-for="(group, i) in tukangGroups" v-bind:key="i" class="columns">
          <div
            v-for="(tukang, j) in $sharedData.tukangtemp.slice(
              i * 4,
              (i + 1) * 4
            )"
            v-bind:key="j"
            class="column  is-one-quarter"
          >
            <p>
              <b-button
                class="is-warning"
                outlined
                expanded
                href="#"
                v-on:click="findTukang(tukang.name)"
              >
                {{ tukang.name }}
              </b-button>
              <br />
            </p>
          </div>
        </div>
      </div>
    </section> -->
    <br />
    <br />
    <br />
    <FooterHome />
  </div>
</template>

<script>
import FooterHome from "./FooterHome.vue";





export default {
  components: {
    FooterHome
  },
  data: function () {
    return {
      tukang: [],
      kawasan: [],
      name: "",
      name2: "",
      name3: "",
      selected: null,

    };
  },
  watch: {
    name2: function () {
      this.name3 = "";
    },
  },
  computed: {
    trimmedTuakngList() {
      return this.$sharedData.tukangtemp.map((tukang) => {
        return tukang.name.substring(0, 20);
      });
    },
    tukangGroups() {
      return Array.from(
        Array(Math.ceil(this.$sharedData.tukangtemp.length / 4)).keys()
      );
    },
    tukangList() {
      // return  this.$sharedData.tukangtemp.map(({ name }) => name)
      return this.$sharedData.tukangtemp.filter((option) => {
        return (
          option.keywords
            .toString()
            .toLowerCase()
            .indexOf(this.name.toLowerCase()) >= 0
        );
      });
    },
    lokasiList() {
      // return  this.$sharedData.tukangtemp.map(({ name }) => name)

      return this.$sharedData.states.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.name2.toLowerCase()) >= 0
        );
      });
    },
    bandarList() {
      // return  this.$sharedData.tukangtemp.map(({ name }) => name)
      if (
        this.$sharedData[this.name2] === undefined ||
        this.name2.match(/seluruh/gi)
      ) {
        return [];
      }
      return this.$sharedData[this.name2].filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.name3.toLowerCase()) >= 0
        );
      });
    },
    postCount() {
      console.log(this.$sharedData.postCount);
      return this.$sharedData.postCount;
    }
  },
  methods: {
    findTukang: function (tukang) {
      if (tukang != undefined) {
        this.name = tukang;
      }
      if (this.name3 == "" || this.name2.length == 0 || this.name.length == 0) {
        console.log(this.name.length);
        if (this.name2.match(/seluruh/gi)) {
          this.name3 = "Semua Negeri";
          if (this.name.length == 0) {
            this.$buefy.toast.open({
              duration: 2000,
              message: `Sila pilih tukang, negeri dan bandar`,
              position: "is-bottom",
              type: "is-danger",
            });
            return;
          }
          console.log("BABI2")
        } else {
          this.$buefy.toast.open({
            duration: 2000,
            message: `Sila pilih tukang, negeri dan bandar`,
            position: "is-bottom",
            type: "is-danger",
          });
          return
        }
      }
      console.log("BAB3")

      this.$router.push({
        name: "Listing",
        params: {
          tukang: this.name,
          kawasan: this.name2,
          bandar: this.name3,
        },
      });
    },
    findAllTukang: function () {
      this.$router.push({
        name: "Listing",
        params: {
          tukang: "Semua Tukang",
          kawasan: "Seluruh Malaysia",
          bandar: "Semua Negeri",
        },
      });
    }
  },

  mounted: function () { },
};
</script>

