var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"hero is-small"},[_c('div',{staticClass:"hero-body"},[_c('p',{staticClass:"title"},[_vm._v("Daftar Iklan")]),_c('b-steps',{attrs:{"type":"is-info","destroy-on-hide":true,"has-navigation":false,"prevIcon":"chevron-left","nextIcon":"chevron-right","icon-pack":"fa"},scopedSlots:_vm._u([{key:"navigation",fn:function(ref){
var next = ref.next;
return (_vm.customNavigation)?[_c('b-button',{staticStyle:{"display":"none"},attrs:{"id":"nextButton","type":"is-warning","size":"is-medium","icon-pack":"fas","icon-right":"chevron-right","disabled":next.disabled},on:{"click":function($event){$event.preventDefault();return next.action($event)}}},[_vm._v(" Next ")])]:undefined}}],null,true)},[_c('b-step-item',{attrs:{"clickable":false,"step":"1","label":"Daftar"}},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-three-quarters"},[_c('div',{staticClass:"container"},[_c('br'),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateForm)}}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Kategori Tukang *","label-position":"on-border","type":{
                          'is-danger': errors[0],
                          'is-success': valid,
                        },"message":errors}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.newPost.tukang),callback:function ($$v) {_vm.$set(_vm.newPost, "tukang", $$v)},expression:"newPost.tukang"}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Pilih satu ...")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Option 1")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Option 2")])])],1)]}}],null,true)}),_c('br'),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Lokasi *","label-position":"on-border","type":{
                          'is-danger': errors[0],
                          'is-success': valid,
                        },"message":errors}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.newPost.lokasi),callback:function ($$v) {_vm.$set(_vm.newPost, "lokasi", $$v)},expression:"newPost.lokasi"}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Pilih satu ...")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Option 1")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Option 2")])])],1)]}}],null,true)}),_c('br'),_c('br'),_c('b-field',{attrs:{"label":"Foto Iklan","label-position":"on-border"}},[_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column has-text-centered"},[_c('image-uploader',{attrs:{"id":"fotoiklan1","preview":true,"className":[
                              'fotoiklan1',
                              { 'fotoiklan1--loaded': _vm.hasImage1 } ],"debug":0,"autoRotate":true,"outputFormat":"string","maxHeight":500,"maxWidth":500},on:{"input":_vm.setImage1}},[_c('label',{attrs:{"slot":"upload-label","for":"fotoiklan1"},slot:"upload-label"},[_c('figure',[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"32","height":"32","viewBox":"0 0 32 32"}},[_c('path',{staticClass:"path1",attrs:{"d":"M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"}})])]),_c('b-tag',{staticClass:"upload-caption"},[_vm._v(_vm._s(_vm.hasImage1 ? "Tukar" : "Foto 1"))])],1)])],1),_c('div',{staticClass:"column has-text-centered"},[_c('image-uploader',{attrs:{"id":"fotoiklan2","preview":true,"className":[
                              'fotoiklan2',
                              { 'fotoiklan2--loaded': _vm.hasImage2 } ],"debug":0,"autoRotate":true,"outputFormat":"string","maxHeight":500,"maxWidth":500},on:{"input":_vm.setImage2}},[_c('label',{attrs:{"slot":"upload-label","for":"fotoiklan2"},slot:"upload-label"},[_c('figure',[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"32","height":"32","viewBox":"0 0 32 32"}},[_c('path',{staticClass:"path1",attrs:{"d":"M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"}})])]),_c('b-tag',{staticClass:"upload-caption"},[_vm._v(_vm._s(_vm.hasImage2 ? "Tukar" : "Foto 2"))])],1)])],1),_c('div',{staticClass:"column has-text-centered"},[_c('image-uploader',{attrs:{"id":"fotoiklan3","preview":true,"className":[
                              'fotoiklan3',
                              { 'fotoiklan3--loaded': _vm.hasImage3 } ],"debug":0,"autoRotate":true,"outputFormat":"string","maxHeight":500,"maxWidth":500},on:{"input":_vm.setImage3}},[_c('label',{attrs:{"slot":"upload-label","for":"fotoiklan3"},slot:"upload-label"},[_c('figure',[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"32","height":"32","viewBox":"0 0 32 32"}},[_c('path',{staticClass:"path1",attrs:{"d":"M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"}})])]),_c('b-tag',{staticClass:"upload-caption"},[_vm._v(_vm._s(_vm.hasImage3 ? "Tukar" : "Foto 3"))])],1)])],1)])]),_c('br'),_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
                            var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Email *","label-position":"on-border","type":{
                          'is-danger': errors[0],
                          'is-success': valid,
                        },"message":errors}},[_c('b-input',{attrs:{"type":"email"},model:{value:(_vm.newPost.email),callback:function ($$v) {_vm.$set(_vm.newPost, "email", $$v)},expression:"newPost.email"}})],1)]}}],null,true)}),_c('br'),_c('b-field',{attrs:{"label":"Deskripsi Ringkas","label-position":"on-border"}},[_c('b-input',{attrs:{"maxlength":"200","type":"textarea","placeholder":"E.g., Saya menyediakan perkhidmatan tukang rumah dan kerja2 berkaitan dengannya"},model:{value:(_vm.newPost.desc),callback:function ($$v) {_vm.$set(_vm.newPost, "desc", $$v)},expression:"newPost.desc"}})],1),_c('b-field',{attrs:{"label":"Senarai Perkhidmatan","label-position":"on-border"}},[_c('b-taginput',{attrs:{"maxtags":"5","type":"is-info","ellipsis":"","icon":"wrench","icon-pack":"fa","placeholder":"E.g., Servis cucian aircond"},model:{value:(_vm.newPost.khidmat),callback:function ($$v) {_vm.$set(_vm.newPost, "khidmat", $$v)},expression:"newPost.khidmat"}})],1),_c('br'),_c('b-field',{attrs:{"label":"Nama Syarikat/Perniagaan","label-position":"on-border"}},[_c('b-input',{attrs:{"maxlength":"30"}})],1),_c('b-field',{attrs:{"label":"No. SSM","label-position":"on-border"}},[_c('b-input',{attrs:{"maxlength":"30"}})],1),_c('validation-provider',{attrs:{"rules":"required|max:12|min:10|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Nombor Telefon *","label-position":"on-border","type":{
                          'is-danger': errors[0],
                          'is-success': valid,
                        },"message":errors}},[_c('b-input',{attrs:{"maxlength":"12"},model:{value:(_vm.newPost.phone),callback:function ($$v) {_vm.$set(_vm.newPost, "phone", $$v)},expression:"newPost.phone"}})],1)]}}],null,true)}),_c('br'),_c('validation-provider',{attrs:{"rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Mod Perhubungan *","label-position":"on-border"}}),_c('br'),_c('b-field',{attrs:{"type":{
                          'is-danger': errors[0],
                          'is-success': valid,
                        },"message":errors}},[_c('b-checkbox',{attrs:{"native-value":"sms","type":"is-info"},model:{value:(_vm.newPost.comms),callback:function ($$v) {_vm.$set(_vm.newPost, "comms", $$v)},expression:"newPost.comms"}},[_vm._v(" SMS ")]),_c('b-checkbox',{attrs:{"native-value":"whatsapp","type":"is-success"},model:{value:(_vm.newPost.comms),callback:function ($$v) {_vm.$set(_vm.newPost, "comms", $$v)},expression:"newPost.comms"}},[_vm._v(" Whatsapp ")]),_c('b-checkbox',{attrs:{"native-value":"call","type":"is-danger"},model:{value:(_vm.newPost.comms),callback:function ($$v) {_vm.$set(_vm.newPost, "comms", $$v)},expression:"newPost.comms"}},[_vm._v(" Call ")])],1)]}}],null,true)}),_c('hr'),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{
                          'is-danger': errors[0],
                          'is-success': valid,
                        },"message":errors}},[_c('b-checkbox',{attrs:{"type":"is-info"},model:{value:(_vm.newPost.agreed),callback:function ($$v) {_vm.$set(_vm.newPost, "agreed", $$v)},expression:"newPost.agreed"}},[_vm._v(" Saya setuju dengan "),_c('a',{attrs:{"href":"#"}},[_vm._v("Terma dan Syarat")])])],1)]}}],null,true)}),_c('br'),_c('validation-provider',{attrs:{"rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{
                          'is-danger': errors[0],
                          'is-success': valid,
                        },"message":errors}},[_c('div',{attrs:{"id":"recaptcha-container"}}),_c('b-input',{staticStyle:{"display":"none"},model:{value:(_vm.recaptchaSolved),callback:function ($$v) {_vm.recaptchaSolved=$$v},expression:"recaptchaSolved"}})],1),_c('br')]}}],null,true)}),_c('nav',{staticClass:"step-navigation"},[_c('b-button',{attrs:{"icon-left":"paper-plane","size":"is-medium","icon-pack":"fa","type":"is-warning","native-type":"submit"}},[_c('strong',[_vm._v("Daftar")])])],1)],1)]}}])})],1)])])]),_c('b-step-item',{attrs:{"clickable":true,"step":"2","label":"Semak"}},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-one-third"},[_c('div',{staticClass:"container has-text-centered"},[_c('img',{attrs:{"src":"https://thumbs.gfycat.com/FantasticDownrightIberianchiffchaff-max-1mb.gif","alt":"Lightweight UI components for Vue.js based on Bulma"}}),_c('h1',{staticClass:"title has-text-centered"},[_vm._v(" Isi Kod Pengesahan 6 digit dari SMS yang anda terima ")]),_c('section',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var invalid = ref.invalid;
return [_c('b-field',{attrs:{"custom-class":"is-large"}},[_c('validation-provider',{attrs:{"rules":"required|length:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-input',{attrs:{"placeholder":"123456","size":"is-large","name":"otpField"},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('p',{staticClass:"control"},[_c('b-button',{attrs:{"label":"","type":"is-warning","size":"is-large","icon-pack":"fa","icon-right":"chevron-right","disabled":invalid},on:{"click":_vm.verifyCode}})],1)],1)]}}])})],1)])])])]),_c('b-step-item',{attrs:{"step":"3","label":"Selesai"}},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-one-third"},[_c('div',{staticClass:"container has-text-centered"},[_c('img',{attrs:{"src":"https://trainingcoach.in/assets/img/success.gif","alt":"Lightweight UI components for Vue.js based on Bulma"}}),_c('h1',{staticClass:"title has-text-centered"},[_vm._v("Terima Kasih")]),_c('h2',{staticClass:"subtitle"},[_vm._v(" Iklan anda telah tersenarai. Tahniah! ")]),_c('b-button',{attrs:{"label":"Kembali","type":"is-warning","size":"is-large","icon-pack":"fa","icon-right":"home"}})],1)])])])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }