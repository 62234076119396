<template>
  <div>
    <section class="hero is-small">
      <div class="hero-body">
        <p class="title">Admin</p>
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <div class="container">
              <b-table :data="categories" :debounce-search="1000">
                <b-table-column
                  field="photo"
                  label="Photo"
                  width="90"
                  v-slot="props"
                >
                  <img :src="props.row.photo" alt />
                </b-table-column>
                <b-table-column field="name" label="Name" v-slot="props">{{
                  props.row.name
                }}</b-table-column>
                <b-table-column label="Delete" v-slot="props">
                  <b-button
                    icon-left="trash"
                    icon-pack="fa"
                    type="is-danger"
                    @click="deleteCategory(props.row.name)"
                  >
                    <strong>Delete</strong>
                  </b-button>
                </b-table-column>
              </b-table>
              <br />

              <form @submit.prevent="createCategory">
                <b-field label="Tukang" label-position="on-border">
                  <b-input v-model="newcategory.name" maxlength="30"></b-input>
                </b-field>
                <b-field label="Foto Iklan" label-position="on-border">
                  <div class="columns is-mobile">
                    <div class="column">
                      <image-uploader
                        id="fotoiklan"
                        :preview="true"
                        :className="[
                          'fotoiklan',
                          { 'fotoiklan--loaded': hasImage },
                        ]"
                        :debug="0"
                        :autoRotate="true"
                        outputFormat="string"
                        :maxHeight="200"
                        :maxWidth="200"
                        @input="setImage"
                      >
                        <label for="fotoiklan" slot="upload-label">
                          <b-tag class="upload-caption">{{
                            hasImage ? "Tukar" : "Foto"
                          }}</b-tag>
                        </label>
                      </image-uploader>
                    </div>
                  </div>
                </b-field>

                <b-button
                  icon-left="paper-plane"
                  size="is-medium"
                  icon-pack="fa"
                  type="is-warning"
                  native-type="submit"
                >
                  <strong>Submit</strong>
                </b-button>

                <br />
                <br />
                <br />
              </form>
              <form @submit.prevent="createBulkCategory">
                <b-field>
                  <b-input type="textarea" v-model="newbulkcategory"> </b-input>
                </b-field>
                <b-button
                  icon-left="paper-plane"
                  size="is-medium"
                  icon-pack="fa"
                  type="is-warning"
                  native-type="submit"
                >
                  <strong>Submit</strong>
                </b-button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from "firebase";
import ImageUploader from "vue-image-upload-resize";

export default {
  data() {
    return {
      hasImage: false,
      categories: [],
      category: {
        name: "",
        photo: "",
      },
      newcategory: {
        name: "",
        photo: "",
      },
      newbulkcategory: "",
    };
  },
  components: {
    ImageUploader,
  },
  methods: {
    setImage: function(file) {
      this.hasImage = true;
      this.newcategory.photo = file;
    },
    deleteCategory(id) {
      firebase
        .firestore()
        .collection("categories")
        .doc(id.toLowerCase())
        .delete()
        .then(() => {
          this.getCategory();
        })
        .catch((error) => {
          alert("Error removing category: ", error);
        });
    },
    getCategory() {
      var docRef = firebase.firestore().collection("categories");
      this.categories = [];
      docRef
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.category = doc.data();
            this.categories.push(this.category);
          });
        })
        .catch((error) => {
          alert("Error getting document:", error);
        });
    },
    createCategory() {
      firebase
        .firestore()
        .collection("categories")
        .doc(this.newcategory.name.toLowerCase())
        .set(this.newcategory)
        .then(() => {
          this.getCategory();
        })
        .catch((error) => {
          alert(error);
        });
    },
    createBulkCategory() {
      var lines = this.newbulkcategory.split("\n");
      for (var i = 0; i < lines.length; i++) {
        var fields = lines[i].split("|");
        if (fields[1] === undefined){
          fields[1] = "";
        }
        let docKey = fields[0].replace(/\W/g, '+')
        firebase
          .firestore()
          .collection("categories")
          .doc(docKey)
          .set({ name: fields[0], keywords: fields[0] + "," + fields[1] })
          .then(() => {
            this.getCategory();
          })
          .catch((error) => {
            alert(error);
          });
      }
    },
  },
  mounted: function() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user.email === "zarulzakuan@gmail.com") {
        this.getCategory();
        console.log("logged in");
      } else {
        console.log("logged out");
      }
    });
  },
};
</script>

<style>
#fotoiklan1,
#fotoiklan2,
#fotoiklan3 {
  display: none;
}
</style>
