<template>
  <b-navbar :fixed-bottom="true" :mobile-burger="false">
    <template #brand>
      <b-navbar-item tag="div">
        <section>
          <b-field v-if="$isMobile()">
            <b-select
              placeholder="Tukang"
              v-model="name"
              style="max-width:25vw;"
            >
              <option
                v-for="tukang in $sharedData.tukangtemp"
                :value="tukang.name"
                :key="tukang.name"
              >
                {{ tukang.name }}
              </option>
            </b-select>
            <b-select
              placeholder="Negeri"
              v-model="name2"
              style="max-width:25vw;"
            >
              <option
                v-for="negeri in $sharedData.states"
                :value="negeri"
                :key="negeri"
              >
                {{ negeri }}
              </option>
            </b-select>
            <b-select
              v-if="showBandar"
              placeholder="Bandar"
              v-model="name3"
              style="max-width:25vw;"
            >
              <option
                v-for="negeri in $sharedData[name2]"
                :value="negeri"
                :key="negeri"
              >
                {{ negeri }}
              </option>
            </b-select>
            <p class="control">
              <b-button
                icon-left="search"
                icon-pack="fa"
                type="is-info is-light"
                v-on:click="findTukang()"
              />
            </p>
          </b-field>
          <b-field v-else>
            <b-autocomplete
              style="max-width:25vw;"
              v-model="name"
              placeholder="Tukang"
              :open-on-focus="true"
              :data="tukangList"
              @select="(option) => (selected = option)"
              field="name"
              :clearable="true"
            >
            </b-autocomplete>
            <b-autocomplete
              style="max-width:25vw;"
              v-model="name2"
              placeholder="Lokasi"
              :open-on-focus="true"
              :data="lokasiList"
              field="name2"
              @select="(option) => (selected = option)"
              :clearable="true"
            >
            </b-autocomplete>
            <b-autocomplete
              v-if="bandarList.length > 0"
              style="max-width:25vw;"
              v-model="name3"
              placeholder="Bandar"
              :open-on-focus="true"
              :data="bandarList"
              @select="(option) => (selected = option)"
              field="name3"
              :clearable="true"
            >
            </b-autocomplete>
            <p class="control">
              <b-button
                icon-left="search"
                icon-pack="fa"
                type="is-info is-light"
                v-on:click="findTukang()"
              />
            </p>
          </b-field>
        </section>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  props: ["name", "name2", "name3"],
  data: function() {
    return {
      tukang: [],
      kawasan: [],
      showBandar: true,
    };
  },
  watch: {
    name2: function() {
      this.name3 = "";
    },
    bandar2: function(newVal) {
      if (newVal.match(/seluruh/gi)) {
        console.log("ahahh");
        this.name3 = "Semua Negeri";
        this.showBandar = false;
      } else {
        console.log("sssssss");
        this.showBandar = true;
      }
    },
  },
  computed: {
    tukangList() {
      // return  this.$sharedData.tukangtemp.map(({ name }) => name)
      return this.$sharedData.tukangtemp.filter((option) => {
        return (
          option.keywords
            .toString()
            .toLowerCase()
            .indexOf(this.name.toLowerCase()) >= 0
        );
      });
    },
    lokasiList() {
      // return  this.$sharedData.tukangtemp.map(({ name }) => name)
      return this.$sharedData.states.filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.name2.toLowerCase()) >= 0
        );
      });
    },
    bandarList() {
      if (this.$sharedData[this.name2] === undefined) {
        return [];
      }

      // return  this.$sharedData.tukangtemp.map(({ name }) => name)
      return this.$sharedData[this.name2].filter((option) => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.name3.toLowerCase()) >= 0
        );
      });
    },
    bandar2: function() {
      return this.name2;
    },
  },

  methods: {
    findTukang: function() {
      if (this.name3 === undefined || this.name3 === "" || this.name2.length == 0 || this.name.length == 0) {
        console.log(this.name.length);
        if (this.name2.match(/seluruh/gi)) {
          this.name3 = "Semua Negeri";
          if (this.name.length == 0) {
            this.$buefy.toast.open({
              duration: 2000,
              message: `Sila pilih tukang, negeri dan bandar`,
              position: "is-bottom",
              type: "is-danger",
            });
            return;
          }
          console.log("BABI2")
        }else{
         this.$buefy.toast.open({
              duration: 2000,
              message: `Sila pilih tukang, negeri dan bandar`,
              position: "is-bottom",
              type: "is-danger",
            });
            return
        }
      }
      console.log("BAB3")
       console.log(this.name);
        console.log(this.name2);
        console.log(this.name3);
      this.$router.push({
        name: "Listing",
        params: {
          tukang: this.name,
          kawasan: this.name2,
          bandar: this.name3,
        },
      });


     
    },
  },
  mounted: function() {
    console.log(this.$isMobile());
  },
};
</script>
