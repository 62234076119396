var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"hero is-small"},[_c('div',{staticClass:"hero-body"},[(_vm.isNewUser)?_c('p',{staticClass:"title"},[_vm._v("Daftar Iklan")]):_c('p',{staticClass:"title"},[_vm._v("Kemaskini Iklan")]),_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-three-quarters"},[_c('div',{staticClass:"container"},[(!_vm.newPost.active && !_vm.isNewPost)?_c('b-message',{attrs:{"title":"Iklan ini tidak aktif","type":"is-danger","closable":false,"has-icon":"","icon":"exclamation-circle","icon-pack":"fas"}},[_vm._v(" Iklan ini telah diset tidak aktif. "),_c('b-button',{attrs:{"icon-left":"trash-alt","size":"is-small","icon-pack":"fa","type":"is-success","native-type":"button"},on:{"click":function($event){return _vm.unsuppressPost()}}},[_c('strong',[_vm._v("Aktif Semula Iklan")])])],1):_vm._e(),(!_vm.userEmailVerfied)?_c('b-message',{attrs:{"title":"Akaun anda masih belum disahkan","type":"is-danger","closable":false,"has-icon":"","icon":"exclamation-circle","icon-pack":"fas"}},[_vm._v(" Anda perlu mengesahkan emel anda terlebih dahulu. "),_c('b-button',{attrs:{"icon-left":"paper-plane","size":"is-small","icon-pack":"fa","type":"is-link","native-type":"button"},on:{"click":function($event){return _vm.sendEmailVerification()}}},[_c('strong',[_vm._v("Hantar Emel Pengesahan")])])],1):_vm._e(),_c('br'),_c('ValidationObserver',{ref:"observer",attrs:{"tag":"form"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.createPost()}}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Kategori Tukang *","label-position":"on-border","type":{
                      'is-danger': errors[0],
                      'is-success': valid,
                    },"message":errors}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.newPost.tukang),callback:function ($$v) {_vm.$set(_vm.newPost, "tukang", $$v)},expression:"newPost.tukang"}},_vm._l((_vm.$sharedData.tukangtemp),function(category){return _c('option',{key:category.name},[_vm._v(" "+_vm._s(category.name)+" ")])}),0)],1)]}}])}),_c('h6',[_vm._v(" Tak jumpa tukang yang anda perlukan? Cadangkan "),_c('a',{on:{"click":function($event){return _vm.$router.push('/cadangan')}}},[_c('b-tag',{attrs:{"icon-left":"search","icon-pack":"fa","size":"is-small","type":"is-link"}},[_c('strong',[_vm._v("di sini")])])],1)]),_c('br'),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Negeri *","label-position":"on-border","type":{
                      'is-danger': errors[0],
                      'is-success': valid,
                    },"message":errors}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.newPost.lokasi),callback:function ($$v) {_vm.$set(_vm.newPost, "lokasi", $$v)},expression:"newPost.lokasi"}},_vm._l((_vm.$sharedData.states),function(state){return _c('option',{key:state},[_vm._v(" "+_vm._s(state)+" ")])}),0)],1)]}}])}),_c('br'),(_vm.showBandar)?_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return [(_vm.showBandar)?_c('b-field',{attrs:{"label":"Bandar *","label-position":"on-border","type":{
                      'is-danger': errors[0],
                      'is-success': valid,
                    },"message":errors}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.newPost.bandar),callback:function ($$v) {_vm.$set(_vm.newPost, "bandar", $$v)},expression:"newPost.bandar"}},_vm._l((_vm.$sharedData[_vm.newPost.lokasi]),function(bandar){return _c('option',{key:bandar},[_vm._v(" "+_vm._s(bandar)+" ")])}),0)],1):_vm._e()]}}],null,false,1834084170)}):_vm._e(),_c('br'),_c('br'),_c('b-field',{attrs:{"label":"Foto Iklan (jika ada)","label-position":"on-border"}},[_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column has-text-centered"},[_c('image-uploader',{attrs:{"id":"fotoiklan1","preview":true,"className":[
                          'fotoiklan1',
                          { 'fotoiklan1--loaded': _vm.hasImage1 } ],"debug":0,"autoRotate":true,"outputFormat":"string","maxHeight":500,"maxWidth":500,"accept":"image/*"},on:{"input":_vm.setImage1}},[_c('label',{attrs:{"slot":"upload-label","for":"fotoiklan1"},slot:"upload-label"},[_c('figure',[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"32","height":"32","viewBox":"0 0 32 32"}},[_c('path',{staticClass:"path1",attrs:{"d":"M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"}})])]),_c('b-tag',{staticClass:"upload-caption",attrs:{"type":"is-warning"}},[_vm._v(_vm._s(_vm.hasImage1 ? "Tukar" : "Foto 1"))])],1)]),(_vm.hasImage1)?_c('b-button',{attrs:{"size":"is-small","type":"is-danger"},on:{"click":_vm.clearImage1}},[_vm._v("Buang")]):_vm._e()],1),_c('div',{staticClass:"column has-text-centered"},[_c('image-uploader',{attrs:{"id":"fotoiklan2","preview":true,"className":[
                          'fotoiklan2',
                          { 'fotoiklan2--loaded': _vm.hasImage2 } ],"debug":0,"autoRotate":true,"outputFormat":"string","maxHeight":500,"maxWidth":500,"accept":"image/*"},on:{"input":_vm.setImage2}},[_c('label',{attrs:{"slot":"upload-label","for":"fotoiklan2"},slot:"upload-label"},[_c('figure',[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"32","height":"32","viewBox":"0 0 32 32"}},[_c('path',{staticClass:"path1",attrs:{"d":"M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"}})])]),_c('b-tag',{staticClass:"upload-caption",attrs:{"type":"is-warning"}},[_vm._v(_vm._s(_vm.hasImage2 ? "Tukar" : "Foto 2"))])],1)]),(_vm.hasImage2)?_c('b-button',{attrs:{"size":"is-small","type":"is-danger"},on:{"click":_vm.clearImage2}},[_vm._v("Buang")]):_vm._e()],1),_c('div',{staticClass:"column has-text-centered"},[_c('image-uploader',{attrs:{"id":"fotoiklan3","preview":true,"className":[
                          'fotoiklan3',
                          { 'fotoiklan3--loaded': _vm.hasImage3 } ],"debug":0,"autoRotate":true,"outputFormat":"string","maxHeight":500,"maxWidth":500,"accept":"image/*"},on:{"input":_vm.setImage3}},[_c('label',{attrs:{"slot":"upload-label","for":"fotoiklan3"},slot:"upload-label"},[_c('figure',[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"32","height":"32","viewBox":"0 0 32 32"}},[_c('path',{staticClass:"path1",attrs:{"d":"M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"}})])]),_c('b-tag',{staticClass:"upload-caption",attrs:{"type":"is-warning"}},[_vm._v(_vm._s(_vm.hasImage3 ? "Tukar" : "Foto 3"))])],1)]),(_vm.hasImage3)?_c('b-button',{attrs:{"size":"is-small","type":"is-danger"},on:{"click":_vm.clearImage3}},[_vm._v("Buang")]):_vm._e()],1)])]),_c('br'),_c('br'),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
                        var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Deskripsi Ringkas *","label-position":"on-border","type":{
                      'is-danger': errors[0],
                      'is-success': valid,
                    },"message":errors}},[_c('b-input',{attrs:{"maxlength":"700","type":"textarea","placeholder":"E.g., Saya menyediakan perkhidmatan tukang rumah dan kerja2 berkaitan dengannya"},model:{value:(_vm.newPost.desc),callback:function ($$v) {_vm.$set(_vm.newPost, "desc", $$v)},expression:"newPost.desc"}})],1)]}}])}),_c('br'),_c('b-field',{attrs:{"label":"Senarai Perkhidmatan Yang Ditawarkan","label-position":"on-border"}},[_c('b-taginput',{attrs:{"maxtags":"5","type":"is-info","ellipsis":"","icon":"wrench","icon-pack":"fa","placeholder":"E.g., Servis cucian aircond"},model:{value:(_vm.newPost.khidmat),callback:function ($$v) {_vm.$set(_vm.newPost, "khidmat", $$v)},expression:"newPost.khidmat"}})],1),_c('br'),_c('b-field',{attrs:{"label":"Nama Perniagaan (jika ada)","label-position":"on-border"}},[_c('b-input',{attrs:{"maxlength":"50"},model:{value:(_vm.newPost.namaPerniagaan),callback:function ($$v) {_vm.$set(_vm.newPost, "namaPerniagaan", $$v)},expression:"newPost.namaPerniagaan"}})],1),_c('b-field',{attrs:{"label":"No. SSM (jika ada)","label-position":"on-border"}},[_c('b-input',{attrs:{"maxlength":"30"},model:{value:(_vm.newPost.SSM),callback:function ($$v) {_vm.$set(_vm.newPost, "SSM", $$v)},expression:"newPost.SSM"}})],1),_c('validation-provider',{attrs:{"rules":"required|max:12|min:10|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Nombor Telefon * (untuk pelanggan menghubungi anda)","label-position":"on-border","type":{
                      'is-danger': errors[0],
                      'is-success': valid,
                    },"message":errors}},[_c('b-input',{attrs:{"maxlength":"12"},model:{value:(_vm.newPost.phone),callback:function ($$v) {_vm.$set(_vm.newPost, "phone", $$v)},expression:"newPost.phone"}})],1)]}}])}),_c('br'),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Mod Perhubungan *","label-position":"on-border"}}),_c('br'),_c('b-field',{attrs:{"type":{
                      'is-danger': errors[0],
                      'is-success': valid,
                    },"message":errors}},[_c('b-checkbox',{attrs:{"native-value":"sms","type":"is-info"},model:{value:(_vm.newPost.comms),callback:function ($$v) {_vm.$set(_vm.newPost, "comms", $$v)},expression:"newPost.comms"}},[_vm._v(" SMS ")]),_c('b-checkbox',{attrs:{"native-value":"whatsapp","type":"is-success"},model:{value:(_vm.newPost.comms),callback:function ($$v) {_vm.$set(_vm.newPost, "comms", $$v)},expression:"newPost.comms"}},[_vm._v(" Whatsapp ")]),_c('b-checkbox',{attrs:{"native-value":"call","type":"is-danger"},model:{value:(_vm.newPost.comms),callback:function ($$v) {_vm.$set(_vm.newPost, "comms", $$v)},expression:"newPost.comms"}},[_vm._v(" Call ")])],1)]}}])}),_c('br'),_c('validation-provider',{attrs:{"rules":{ required: _vm.required, regex: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return [_c('b-field',{attrs:{"label":"Website (jika ada)","label-position":"on-border","type":{
                      'is-danger': errors[0],
                      'is-success': valid,
                    },"message":errors}},[_c('b-input',{attrs:{"maxlength":"100"},model:{value:(_vm.newPost.website),callback:function ($$v) {_vm.$set(_vm.newPost, "website", $$v)},expression:"newPost.website"}})],1)]}}])}),_c('hr'),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{
                      'is-danger': errors[0],
                      'is-success': valid,
                    },"message":errors}},[_c('b-checkbox',{attrs:{"type":"is-info"},model:{value:(_vm.newPost.agreed),callback:function ($$v) {_vm.$set(_vm.newPost, "agreed", $$v)},expression:"newPost.agreed"}},[_vm._v(" Saya setuju dengan "),_c('a',{on:{"click":function($event){return _vm.showPDPA()}}},[_vm._v("Terma dan Syarat")])])],1)]}}])}),_c('b-modal',{model:{value:(_vm.isModalActive),callback:function ($$v) {_vm.isModalActive=$$v},expression:"isModalActive"}},[_c('div',{staticClass:"container has-text-centered"},[_c('b-button',{staticStyle:{"height":"200px"},attrs:{"size":"is-large","icon-pack":"fa","type":"is-warning"}},[_c('b-icon',{attrs:{"icon":"party","pack":"fas"}}),_c('br'),_c('strong',[_vm._v("Pakej 1 Bulan")]),_c('br'),_c('small',[_vm._v("asddsadsdasds")])],1)],1)]),_c('b-button',{attrs:{"disabled":!_vm.newPost.active && !_vm.isNewPost,"icon-left":"paper-plane","size":"is-medium","icon-pack":"fa","type":"is-warning","native-type":"submit"}},[_c('strong',[_vm._v(_vm._s(_vm.isNewPost ? "Daftar" : "Kemaskini"))])]),_c('br'),_c('br'),_c('b-button',{attrs:{"disabled":!_vm.newPost.active,"icon-left":"pause","size":"is-small","icon-pack":"fa","type":"is-danger","native-type":"button"},on:{"click":function($event){return _vm.suppressPost()}}},[_c('strong',[_vm._v("Henti Iklan")])]),_c('b-button',{attrs:{"disabled":_vm.newPost.active || _vm.isNewPost,"icon-left":"play","size":"is-small","icon-pack":"fa","type":"is-success","native-type":"button"},on:{"click":function($event){return _vm.unsuppressPost()}}},[_c('strong',[_vm._v("Aktif Semula Iklan")])]),_c('b-button',{attrs:{"disabled":_vm.isNewPost,"icon-left":"trash-alt","size":"is-small","icon-pack":"fa","type":"is-dark","native-type":"button"},on:{"click":function($event){return _vm.deletePost()}}},[_c('strong',[_vm._v("Buang Iklan")])]),_c('br'),_c('br'),_c('br')],1)])],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }